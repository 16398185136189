const DueActivityLoader = () => {
    return (
        <div className="mt-10 grid md:grid-cols-3 grid-cols-1 gap-5">
            <SingleDueActivityLoader />
            <SingleDueActivityLoader />
            <SingleDueActivityLoader />
        </div>
    )
}


const SingleDueActivityLoader = () => {
    return (
        <div>
            <div className="w-full h-[200px] animate-pulse bg-gray-200 rounded-md">
            </div>
            <div className="mt-3">
                <div className="w-20 h-4 animate-pulse bg-gray-200">
                </div>
                <div className="w-32 mt-1 h-4 animate-pulse bg-gray-200">
                </div>
                <div className="w-full mt-1 h-8 animate-pulse bg-gray-200">
                </div>
            </div>
        </div>
    )
}


export default DueActivityLoader