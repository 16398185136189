import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { LoadingSvg } from "../components/Buttons";
import NotFound from "../pages/NotFound";
import Account from "../layout/account.jsx";
import AppHome from "../pages/app/me.jsx";
import Settings from "../pages/app/settings.jsx";
import CreatePrograms from "../pages/app/programs/create.jsx";
import ViewPrograms from "../pages/app/programs/view.jsx";
import ManageProgramContent from "../pages/app/programs/manage/content.jsx";
import ViewCompleted from "../pages/app/programs/manage/view-completed.jsx";
import CreateActivites from "../pages/app/programs/activites/create.jsx";

// const AccountLayout = React.lazy(() => import("../layout/account.jsx"));

const Home = React.lazy(() => import("../pages/index.jsx"));
const Login = React.lazy(() => import("../pages/login.jsx"));
const Register = React.lazy(() => import("../pages/register.jsx"));
const ForgetPassword = React.lazy(() => import("../pages/forget-password.jsx"));
const ResetPassword = React.lazy(() => import("../pages/reset-password.jsx"));
const JoinProgram = React.lazy(() => import("../pages/join/program.jsx"));
const Terms = React.lazy(() => import("../pages/terms.jsx"));

// const AppHome = React.lazy(() => import("../pages/app/me"));
// const Settings = React.lazy(() => import("../pages/app/settings"));
// const CreatePrograms = React.lazy(() => import("../pages/app/programs/create"));
// const ViewPrograms = React.lazy(() => import("../pages/app/programs/view"));
// const ManageProgramContent = React.lazy(() =>
//   import("../pages/app/programs/manage/content")
// );
// const ViewCompleted = React.lazy(() =>
//   import("../pages/app/programs/manage/view-completed")
// );
// const CreateActivites = React.lazy(() =>
//   import("../pages/app/programs/activites/create")
// );
const ProgramInvitation = React.lazy(() =>
  import("../pages/program/invitation")
);

const Navigation = () => {
  return (
    <>
      <ToastContainer />
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-screen w-full bg-blue-50">
            <div>
              <LoadingSvg />
            </div>
          </div>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/join/:programLink" element={<JoinProgram />} />
            <Route path="/program/invitation/:programLink" element={<ProgramInvitation />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/app/me" element={<Account />}>
              <Route path="" element={<AppHome />} />
              <Route path="settings" element={<Settings />} />
              <Route path="programs">
                <Route path="create" element={<CreatePrograms />} />
                <Route path="view" element={<ViewPrograms />} />
                <Route path="manage">
                  <Route path="content" element={<ManageProgramContent />} />
                  <Route
                    path="view-completed/:date"
                    element={<ViewCompleted />}
                  />
                </Route>
                <Route path="activities/:programId">
                  <Route path="create" element={<CreateActivites />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default Navigation;
