export const generateSources = (data) => {
  const p360 = {
    label: "360p",
    src: data.p360,
    type: "video/mp4",
    res: 360,
  };
  const p480 = {
    label: "480p",
    src: data.p480,
    type: "video/mp4",
    res: 480,
  };
  const p720 = {
    label: "720p",
    src: data.p720,
    type: "video/mp4",
    res: 720,
  };
  const p1080 = {
    label: "1080p",
    src: data.p1080,
    type: "video/mp4",
    res: 1080,
  };
  let sources = [p360, p480, p720, p1080];
  let list = sources.filter(
    (source) => source.src !== null && source.src !== ""
  );
  return list;
};
