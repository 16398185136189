import { useId, useState } from "react";
import { useEventListener } from "../../hooks/useEventListener";

const Menu = ({ Icon, children }) => {
  const id = useId();
  const targetId = `dropdown-${id}`;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleEscape = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  useEventListener("keydown", handleEscape);

  return (
    <>
      <div className="relative">
        <button
          id={id}
          data-dropdown-toggle={targetId}
          className="inline-flex items-center p-2 hover:bg-gray-200 text-center text-gray-900 rounded-lg"
          type="button"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={handleToggle}
        >
          <Icon />
        </button>
        {/* Dropdown menu */}
        {isOpen && (
          <div
            id={targetId}
            className="absolute right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby={id}
            >
              {children}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Menu;
