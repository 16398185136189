"use client";

import { createContext, useContext } from "react";
import { useState } from "react";

const AuthContext = createContext({
  user: null,
  token: null,
  // eslint-disable-next-line no-unused-vars
  login: ({ userData, token }, callback = () => {}) => {},
  updateUserData: () => {},
  logout: () => {},
  setToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    else return null;
  });

  const updateUserData = (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
  };

  const [token, updateToken] = useState(() => {
    let token = null;
    token = localStorage.getItem("token");
    return token;
  });

  const login = async ({ userData, token }, callback) => {
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("token", token);
    setUser(userData);
    updateToken(token);
    if (callback) callback();
  };

  const logout = (callback) => {
    setUser(null);
    updateToken(null);

    localStorage.removeItem(`user`);
    localStorage.removeItem(`token`);

    if (callback) callback();
  };

  const value = {
    user,
    token,
    updateUserData,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
