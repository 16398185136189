import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Buttons";
import TextInput from "../../../components/Inputs/TextInput";
import TextAreaInput from "../../../components/Inputs/TextareaInput";
import Switch from "../../../components/Inputs/Switch";
import { useFormik } from "formik";
import * as Yup from "yup";
import http from "../../../api/http";
import useAuth from "../../../protector/AuthService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { IoIosArrowRoundBack } from "react-icons/io";
import FileInput from "../../../components/Inputs/FIleInput";
import Datepicker from "react-tailwindcss-datepicker";

const getYesterdaysDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString().split("T")[0];
};

const CreatePrograms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [navigating, setNavigating] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [canEditStartDate, setCanEditStartDate] = useState(true);
  const [canEditEndDate, setCanEditEndDate] = useState(true);
  const [image, setImage] = useState(null);
  const [allowedEmails, setAllowedEmails] = useState([]);
  const [textInAllowedEmails, setTextInAllowedEmails] = useState("");
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: "",
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      aim: "",
      tagline: "",
      isPublic: true,
      joinProgram: false,
      startDate: "",
      endDate: "",
      extension: 5,
      showFutureActivity: false,
      showFutureActivityUpToDays: 3,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      aim: Yup.string(),
      tagline: Yup.string(),
      isPublic: Yup.boolean(),
      startDate: location?.state?.program
        ? Yup.date().required("Start date is required")
        : Yup.date()
            .required("Required")
            .min(
              new Date(getYesterdaysDate()),
              "Start date can't be in the past"
            ),
      endDate: Yup.date().required("End date is required"),
      showFutureActivity: Yup.boolean(),
      // TODO: Validation for showFutureActivityUpToDays
      showFutureActivityUpToDays: Yup.number().when("showFutureActivity", {
        is: true,
        then: (schema) =>
          schema.required("Required") && schema.min(1) && schema.max(9),
      }),
    }),
    onSubmit: async (values) => {
      // const payload = {};
      const formData = new FormData();

      for (const key in values) {
        if (values[key] !== "") {
          if (
            key === "isPublic" ||
            key === "joinProgram" ||
            key === "showFutureActivity"
          ) {
            formData.append(key, values[key] ? 1 : 0);
            continue;
          }
          formData.append(key, values[key]);
        }
      }

      if (image) {
        formData.append("image", image);
      }

      if (allowedEmails.length > 0) {
        formData.append("allowedEmails", allowedEmails.join(","));
      }

      let req;

      try {
        if (!isEdit) {
          req = await http.post("/program", formData, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          navigate(`/app/me/programs/activities/${req.data.id}/create`, {
            state: { program: req.data },
          });
        } else {
          req = await http.patch(
            `/program/${location.state.program.id}`,
            {
              ...values,
              isPublic: values.isPublic ? "1" : "0",
              allowedEmails: allowedEmails.join(","),
            },
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            }
          );
          // location.state.program = req.data;
          navigate(-1);
        }
        if (isEdit) {
          toast.success("Program updated successfully");
        } else {
          toast.success("Program created successfully");
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
      }
    },
  });

  useEffect(() => {
    if (location.state) {
      setIsEdit(true);
      formik.setValues({
        title: location.state.program.title,
        description: location.state.program.description,
        aim: location.state.program.aim || "",
        tagline: location.state.program.tagline || "",
        isPublic: location.state.program.isPublic,
        joinProgram: location.state.program.joinProgram,
        startDate: format(
          new Date(location.state.program.startDate),
          "yyyy-MM-dd"
        ),
        endDate: format(new Date(location.state.program.endDate), "yyyy-MM-dd"),
        extension: location.state.program.extension,
        showFutureActivity: location.state.program.showFutureActivity,
        showFutureActivityUpToDays:
          location.state.program.showFutureActivityUpToDays,
      });

      const allowedEmails = location.state?.program?.AllowedEmailForProgram?.map(
        (email) => email.email
      );

      setTextInAllowedEmails(allowedEmails.join(", "));
      setAllowedEmails(allowedEmails);

      console.log(allowedEmails);

      const program = location.state.program;
      let canEditStart;
      let canEditEnd;

      const today = new Date();
      if (new Date(program.startDate) > today) {
        canEditStart = true;
        setCanEditStartDate(canEditStart);
      } else {
        canEditStart = false;
        setCanEditStartDate(canEditStart);
      }
      if (new Date(program.endDate) > today) {
        canEditEnd = true;
        setCanEditEndDate(canEditEnd);
      } else {
        canEditEnd = false;
        setCanEditEndDate(canEditEnd);
      }

      if (canEditStart && canEditEnd) {
        setValue({
          startDate: new Date(program.startDate),
          endDate: new Date(program.endDate),
        });
      }

      if (!canEditStart) {
        setValue({
          startDate: new Date(program.endDate),
          endDate: new Date(program.endDate),
        });
      }
    }
  }, [location.state]);

  const navigateToEditActivities = async () => {
    setNavigating(true);
    try {
      const res = await http.get(
        `/program/activities/${location.state.program.id}`
      );
      navigate(
        `/app/me/programs/activities/${location.state.program.id}/create`,
        {
          state: { program: location.state.program, activities: res.data },
        }
      );
      setNavigating(false);
    } catch (error) {
      toast.error("Unable to fetch activities");
      setNavigating(false);
    }
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={() => navigate(-1)}>
        <IoIosArrowRoundBack className="w-8 h-8" />
      </div>
      <h2 className="text-2xl font-bold">
        <span> {isEdit ? "Edit Program" : "Create Program"}</span>
      </h2>
      <p className="text-gray-400">
        {isEdit ? "Edit the details of your program" : "Create a new program"}
      </p>
      <div className="mt-10 max-w-xl">
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-5">
            <div>
              <TextInput
                name="title"
                label="Program Title"
                placeholder="e.g The Future of Works"
                onChange={formik.handleChange}
                value={formik.values.title}
                error={formik.touched.title && formik.errors.title}
              />
            </div>
            {!isEdit && (
              <div>
                <FileInput
                  name="image"
                  label="Program Image"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            )}
            <div>
              <TextAreaInput
                name="description"
                label="Description"
                placeholder="What's is the program about?"
                rows={6}
                onChange={formik.handleChange}
                value={formik.values.description}
                error={formik.touched.description && formik.errors.description}
              />
            </div>
            <div>
              <TextInput
                name="aim"
                label="Aim (Optional)"
                placeholder="What's is the program about?"
                rows={6}
                onChange={formik.handleChange}
                value={formik.values.aim}
                error={formik.touched.aim && formik.errors.aim}
              />
            </div>
            {!isEdit && (
              <div>
                <Switch
                  name="isPublic"
                  value={formik.values.isPublic}
                  label="Is this program public?"
                  onChange={formik.handleChange}
                />
                <p className="text-xs text-gray-500">
                  If you make this program public, it will be visible to
                  everyone and anyone can join. If you make this program
                  private, anyone can join but only with the link.
                </p>
              </div>
            )}
            {!isEdit && (
              <div>
                <Switch
                  name="joinProgram"
                  value={formik.values?.joinProgram}
                  label="Automatically join this program?"
                  onChange={formik.handleChange}
                />
                <p className="text-xs text-gray-500">
                  Enabling this will automatically join you to this program once
                  it&apos;s created.
                </p>
              </div>
            )}
            {!formik.values.isPublic && (
              <div>
                <TextAreaInput
                  name="textInAllowedEmails"
                  rows={6}
                  value={textInAllowedEmails}
                  label={
                    <>
                      <div className="flex items-center justify-between">
                        <span>
                          {" "}
                          Paste emails here, or type separated with commas
                        </span>
                        <span className="text-sm text-blue-500">
                          {allowedEmails.length} emails found
                        </span>
                      </div>
                    </>
                  }
                  onChange={(e) => {
                    let v = e.target.value;
                    setTextInAllowedEmails(v);
                  }}
                  onBlur={(e) => {
                    let v = e.target.value + ",";

                    if (v === "") {
                      v = [];
                    }

                    if (v.includes(",")) {
                      v = v
                        .split(",")
                        .filter((email) => email !== "")
                        .map((email) => email.trim().toLowerCase())
                        .filter((email) =>
                          Yup.string().email().isValidSync(email)
                        );
                    } else if (v.includes("\n")) {
                      v = v
                        .split("\n")
                        .filter((email) => email !== "")
                        .map((email) => email.trim().toLowerCase())
                        .filter((email) =>
                          Yup.string().email().isValidSync(email)
                        );
                    } else {
                      v = [];
                    }

                    setTextInAllowedEmails(v.join(", "));
                    setAllowedEmails(v);
                  }}
                />
                <p className="text-sm text-gray-500">
                  Enter email ID of all members
                </p>
              </div>
            )}
            <div>
              <TextInput
                name="tagline"
                label="Tagline (Optional)"
                placeholder="A tagline for this program"
                onChange={formik.handleChange}
                value={formik.values.tagline}
                error={formik.touched.tagline && formik.errors.tagline}
              />
            </div>
            <div>
              <label className="block text-gray-500 font-medium cursor-pointer">
                Duration {canEditStartDate && " (Start Date)"}{" "}
                {canEditEndDate && " (End Date)"}
              </label>
              <Datepicker
                containerClassName="date-picker relative"
                inputClassName="flex relative items-center justify-start border-2 border-gray-400 rounded mt-1 placeholder:text-gray-400 text-gray-500 focus-within:border-gray-700 duration-150 px-3 py-3 rounded w-full outline-none disabled:cursor-not-allowed"
                separator="to"
                showShortcuts={false}
                value={value}
                useRange={canEditStartDate && canEditEndDate}
                asSingle={!(canEditStartDate && canEditEndDate)}
                onChange={(newValue) => {
                  setValue(newValue);
                  if (canEditStartDate && canEditEndDate) {
                    formik.setFieldValue("startDate", newValue.startDate);
                    formik.setFieldValue("endDate", newValue.endDate);
                  } else if (canEditStartDate) {
                    formik.setFieldValue("startDate", newValue.startDate);
                  } else if (canEditEndDate) {
                    formik.setFieldValue("endDate", newValue.endDate);
                  }
                }}
              />
              {formik.touched.startDate && formik.errors.startDate && (
                <span className="text-red-500 text-xs">
                  {formik.errors.startDate}
                </span>
              )}
              {formik.touched.endDate && formik.errors.endDate && (
                <span className="text-red-500 text-xs">
                  {formik.errors.endDate}
                </span>
              )}
            </div>
            <div className="text-gray-500">
              Members can still submit task{" "}
              <span className="mx-2">
                <input
                  name="extension"
                  value={formik.values.extension}
                  onChange={formik.handleChange}
                  type="number"
                  className="w-10 px-2 py-1"
                  max={10}
                  min={0}
                />
              </span>{" "}
              days after program ends
            </div>
            <div>
              <Switch
                name="showFutureActivity"
                value={formik.values.showFutureActivity}
                label="Do you want your members to see upcoming days tasks?"
                onChange={formik.handleChange}
              />
              <p className="text-xs text-gray-500">
                If you enable this, your members will be able to see and read
                upcoming days tasks. They will not be able to submit their tasks
                before the day starts. (You can also set how many days in
                advance you want to show upcoming tasks.)
              </p>
            </div>
            {formik.values.showFutureActivity && (
              <div>
                <TextInput
                  name="showFutureActivityUpToDays"
                  label="How many days in advance do you want to show upcoming tasks?"
                  placeholder="e.g 3"
                  type="number"
                  min={1}
                  max={9}
                  onChange={formik.handleChange}
                  value={formik.values.showFutureActivityUpToDays}
                  error={
                    formik.touched.showFutureActivityUpToDays &&
                    formik.errors.showFutureActivityUpToDays
                  }
                />
                <p className="text-xs text-gray-500">
                  How many days in advance do you want to show upcoming tasks?
                  e.g 3 means you will show tasks for tomorrow, the next and the
                  day after next tomorrow. Minimum 1 day and maximum 9 days.
                </p>
              </div>
            )}
            <div className="flex justify-end">
              {isEdit && (
                <Button
                  variant="outline"
                  type="button"
                  onClick={navigateToEditActivities}
                  loading={navigating}
                >
                  Update Activities
                </Button>
              )}
              <Button type="submit" loading={formik.isSubmitting}>
                {isEdit ? "Update " : "Create "}
                program
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePrograms;
