// write a function that takes a date and return how many days more to the given date
export const dueTimes = (date) => {
  const today = new Date();
  const dueDate = new Date(new Date(date).toISOString().split("T")[0]); // Use 'T' as the delimiter
  const timeDiff = dueDate.getTime() - today.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);
  return Math.ceil(daysDiff);
};

// write a function that takes a date and return how many days passed from the present date to the  given date
export const passedDays = (date) => {
  const today = new Date();
  const dueDate = new Date(new Date(date).toISOString().split("T")[0]); // Use 'T' as the delimiter
  const timeDiff = today.getTime() - dueDate.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);
  return Math.ceil(daysDiff);
};

// write a function that takes two dates start date, end end date and return how many days passed from the start date to the end date date
// then if it the same date return 'same day'
export const passedDaysFromTo = (startDate, endDate) => {
  const start = new Date(new Date(startDate).toISOString().split("T")[0]); // Use 'T' as the delimiter
  const end = new Date(new Date(endDate).toISOString().split("T")[0]); // Use 'T' as the delimiter

  const timeDiff = end.getTime() - start.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  const d = Math.ceil(daysDiff);

  return {
    daysDiff: d,
    sameDay: d === 0,
  };
};
