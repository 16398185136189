import { useState } from "react";
import { Button } from "../../components/Buttons";
import Card from "../../components/Cards/Card";
import Modal from "../../components/Modal/Modal";
import {
  PasswordInput,
  TextAreaInput,
  TextInput,
} from "../../components/Inputs";
import useAuth from "../../protector/AuthService";
import { useFormik } from "formik";
import * as yup from "yup";
import http from "../../api/http";
import { toast } from "react-toastify";

const Settings = () => {
  const [editUserModal, setEditUserModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const auth = useAuth();

  const updatePasswordForm = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: yup.object({
      oldPassword: yup.string().required("Required"),
      newPassword: yup
        .string()
        .required("Required")
        .min(6, "Password is too short"),
    }),
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      try {
        await http.post("/users/change-password", values, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        toast.success("Password changed successfully");
        setChangePasswordModal(false);
        helpers.resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.error);
      }
    },
  });

  const updateProfileForm = useFormik({
    initialValues: {
      twitterHandle: auth.user?.twitterHandle || "",
      bio: auth.user?.bio || "",
      tagline: auth.user?.tagline || "",
      displayName: auth.user?.displayName || "",
    },
    validationSchema: yup.object({
      tagline: yup.string().required("Required"),
      twitterHandle: yup.string().required("Required"),
      bio: yup.string().required("Required"),
    }),
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      try {
        const req = await http.post("/users/update", values, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        toast.success("Profile updated successfully");
        auth.updateUserData(req.data);
        setEditUserModal(false);
        helpers.resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.error);
      }
    },
  });

  return (
    <>
      <Modal
        title="Edit profile"
        isOpen={editUserModal}
        onClose={() => {
          setEditUserModal(false);
          updateProfileForm.resetForm();
        }}
        actions={
          <>
            <Button
              variant="solid"
              type="submit"
              size="sm"
              onClick={() => updateProfileForm.handleSubmit()}
              loading={updateProfileForm.isSubmitting}
            >
              Save
            </Button>
          </>
        }
      >
        <div className="space-y-5">
          <div className="grid grid-cols-2 gap-5">
            <TextInput
              name="twitterHandle"
              label="Twitter handle"
              onChange={updateProfileForm.handleChange}
              value={updateProfileForm.values.twitterHandle}
              error={
                updateProfileForm.touched.twitterHandle &&
                updateProfileForm.errors.twitterHandle
              }
            />
            <TextInput
              name="displayName"
              label="Display Name"
              onChange={updateProfileForm.handleChange}
              value={updateProfileForm.values.displayName}
              error={
                updateProfileForm.touched.displayName &&
                updateProfileForm.errors.displayName
              }
            />
          </div>
          <div>
            <TextInput
              name="tagline"
              label="Tagline"
              onChange={updateProfileForm.handleChange}
              value={updateProfileForm.values.tagline}
              error={
                updateProfileForm.touched.tagline &&
                updateProfileForm.errors.tagline
              }
            />
          </div>
          <div>
            <TextAreaInput
              name="bio"
              label="Bio"
              onChange={updateProfileForm.handleChange}
              value={updateProfileForm.values.bio}
              error={
                updateProfileForm.touched.bio && updateProfileForm.errors.bio
              }
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="Change password"
        isOpen={changePasswordModal}
        onClose={() => {
          updatePasswordForm.resetForm();
          setChangePasswordModal(false);
        }}
        actions={
          <>
            <Button
              variant="solid"
              size="sm"
              type="submit"
              onClick={() => updatePasswordForm.handleSubmit()}
              loading={updatePasswordForm.isSubmitting}
            >
              Update password
            </Button>
          </>
        }
      >
        <>
          <div className="space-y-5">
            <div>
              <PasswordInput
                name="oldPassword"
                label="Current password"
                onChange={updatePasswordForm.handleChange}
                value={updatePasswordForm.values.oldPassword}
                error={
                  updatePasswordForm.touched.oldPassword &&
                  updatePasswordForm.errors.oldPassword
                }
              />
            </div>
            <div>
              <PasswordInput
                name="newPassword"
                label="New Password"
                onChange={updatePasswordForm.handleChange}
                value={updatePasswordForm.values.newPassword}
                error={
                  updatePasswordForm.touched.newPassword &&
                  updatePasswordForm.errors.newPassword
                }
              />
            </div>
          </div>
        </>
      </Modal>

      <div>
        <h1 className="text-xl text-slate-800 font-medium">Your Profile</h1>
        <p>
          This information will be displayed publicly.
        </p>
        <Card className="mt-10 max-w-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-12 h-12 bg-blue-500 rounded-full"></div>
              <div className="ml-4">
                <h1 className="text-xl text-slate-800 font-medium">
                  {auth.user?.lastName} {auth.user?.firstName}
                </h1>
                <p className="text-sm text-slate-400">
                  {auth.user?.tagline ||
                    "No tagline yet. Edit your profile to add one."}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h3 className="uppercase text-sm text-gray-700-400 font-semibold mt-8">
              bio
            </h3>
            <p className="text-gray-400">
              {auth.user?.bio || "No bio yet. Edit your profile to add one."}
            </p>
            <div className="grid md:grid-cols-2 grid-cols-1">
              <div>
                <h3 className="uppercase text-xs text-gray-700-400 font-semibold mt-8">
                  Twitter Handle
                </h3>
                <p className="text-gray-400">
                  {auth.user?.twitterHandle ||
                    "No twitter handle yet. Edit your profile to add one."}
                </p>
              </div>
              <div>
                <h3 className="uppercase text-xs text-gray-700-400 font-semibold mt-8">
                  Display Name
                </h3>
                <p className="text-gray-400">
                  {auth.user?.displayName ||
                    "No display name yet. Edit your profile to add one."}
                </p>
              </div>
              <div>
                <h3 className="uppercase text-xs text-gray-700-400 font-semibold mt-8">
                  Gender
                </h3>
                <p className="text-gray-400">
                  {auth.user?.gender ||
                    "No gender yet. Edit your profile to add one."}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-end mt-8 space-x-2">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setEditUserModal(true);
                }}
              >
                Edit Profile
              </Button>
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setChangePasswordModal(true);
                }}
              >
                Change Password
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Settings;
