import { Outlet, useNavigate } from "react-router-dom";

import Sidebar from "../components/Sidebar/Sidebar";
import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineMenu } from "react-icons/hi";
import { Suspense, useEffect, useState } from "react";
import useAuth from "../protector/AuthService";
import { Helmet } from "react-helmet";
import { LoadingSvg } from "../components/Buttons";
import Notification from "../components/Notification/Notification";

const Account = () => {
  const [openMobile, setOpenMobile] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !auth.token ||
      auth.token === "" ||
      auth.token === null ||
      auth.token === undefined ||
      !auth.user ||
      auth.user === "" ||
      auth.user === null ||
      auth.user === undefined
    ) {
      navigate("/login", {
        replace: true,
      });
    }
  }, [auth.token, auth.user]);

  return (
    <>
      <Helmet>
        <title>Account | dailydoai</title>
      </Helmet>
      <div className="h-screen w-full flex items-start justify-start bg-gray-100 overflow-hidden relative">
        <Sidebar openMobile={openMobile} setOpenMobile={setOpenMobile} />
        <div className="w-full h-full overflow-y-scroll">
          <div>
            <div className="flex">
              <div
                onClick={() => setOpenMobile(true)}
                className="p-4 lg:hidden flex bg-blue-500 flex-shrink-0 items-center justify-center cursor-pointer"
              >
                <button>
                  <HiOutlineMenu className="w-6 h-6 text-gray-100" />
                </button>
              </div>
              <div className="bg-gray-50 border-b-2 border-gray-200 w-full">
                <div className="container w-full flex items-center justify-start mx-auto">
                  <div>
                    <AiOutlineSearch className="w-6 h-6 text-gray-400" />
                  </div>
                  <input
                    type="search"
                    autoComplete="false"
                    aria-autocomplete="none"
                    className="outline-none w-full md:py-8 py-5 px-2 text-gray-400 placeholder:text-gray-400 bg-transparent"
                    placeholder="search for a creator name or title ..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto py-10">
            <Suspense
              fallback={
                <div className="flex items-center justify-center h-screen w-full bg-blue-50">
                  <div>
                    <LoadingSvg />
                  </div>
                </div>
              }
            >
              <Notification />
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
