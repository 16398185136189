import { useEffect, useState } from "react";
import http from "../../../../api/http";
import TitleCards from "../../../../components/Cards/TitleCards";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineChevronRight, HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosArrowRoundBack, IoIosShareAlt } from "react-icons/io";
import { Button } from "../../../../components/Buttons";
import Modal from "../../../../components/Modal/Modal";
import { toast } from "react-toastify";
import useAuth from "../../../../protector/AuthService";
import Menu from "../../../../components/Menu/Menu";
import MenuItem from "../../../../components/Menu/MenuItem";
import TextInput from "../../../../components/Inputs/TextInput";
import fileDownload from "js-file-download";
// import SelectInput from "../../../../components/Inputs/Select";

const userStatus = (status) => {
  if (status === "ENROLLED_ACTIVE") {
    return "Enrolled";
  }
  if (status === "UNENROLLED_USER_UNENROLLED") {
    return "Self Unenrolled";
  }

  if (status === "UNENROLLED_USER_REMOVED") {
    return "Removed";
  }
};

const getEnrolledStudents = async (programId) => {
  try {
    const req = await http.get(`program/user-in-program/${programId}`);
    return [req, null];
  } catch (error) {
    return [null, error];
  }
};

const getProgramInfo = async (programId) => {
  try {
    const req = await http.get(`program/info/${programId}`);
    return [req, null];
  } catch (error) {
    return [null, error];
  }
};

const getJoinRequests = async (token, programId) => {
  try {
    const req = await http.get(`program/join-request/${programId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return [req, null];
  } catch (error) {
    return [null, error];
  }
};

const ManageProgramContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const [enrolledUser, setEnrolledUser] = useState({});
  const [programInfo, setProgramInfo] = useState([]);
  const [downloadingUsers, setDownloadingUsers] = useState(false);
  const [downloadingJoinRequest, setDownloadingJoinRequest] = useState(false);
  const [deactivateProgram, setDeactivateProgram] = useState(false);
  const [deleteProgram, setDeleteProgram] = useState(false);
  const [removeUser, setRemoveUser] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [joinRequest, setJoinRequest] = useState([]);
  const [addManager, setAddManager] = useState(false);
  const [managerEmail, setManagerEmail] = useState("");
  const [loading, setLoading] = useState({
    program: false,
    approval: false,
    removingUser: false,
    addManager: false,
  });

  const getJoinRequestHandler = async () => {
    getJoinRequests(auth.token, location.state.program.id).then(
      ([res, err]) => {
        if (err) return;
        setJoinRequest(res.data);
      }
    );
  };

  useEffect(() => {
    if (!location?.state?.program) {
      navigate("/app/me");
      return;
    }

    getEnrolledStudents(location.state?.program?.id).then(([res, err]) => {
      if (err) return;
      setEnrolledUser(res.data);
    });
    getProgramInfo(location?.state?.program?.id).then(([res, err]) => {
      if (err) return;
      setProgramInfo(res.data);
    });
    getJoinRequestHandler();
  }, [location.state]);

  const downloadUsers = async () => {
    setDownloadingUsers(true);
    try {
      const res = await http.get(
        `program/download-enrolled/${location.state.program.id}`,
        {
          responseType: "blob",
        }
      );
      console.log(res);
      toast.success("Downloading...");
      setDownloadingUsers(false);

      const programTitle = programInfo.program.title;
      const enrolledusersCount =
        programInfo.userEnrolledCount -
        programInfo.unenrolledUserCount -
        programInfo.removedUserCount;

      fileDownload(
        res.data,
        `${programTitle}_enrolledusers_${enrolledusersCount}.csv`
      );
    } catch (error) {
      setDownloadingUsers(false);
      toast.error("Something went wrong");
      toast.error(error?.response?.data?.message);
    }
  };

  const downloadJoinRequest = async () => {
    setDownloadingJoinRequest(true);
    try {
      const res = await http.get(
        `program/download-requested/${location.state.program.id}`,
        {
          responseType: "blob",
        }
      );
      toast.success("Downloading...");
      setDownloadingJoinRequest(false);

      const programTitle = programInfo.program.title;

      fileDownload(
        res.data,
        `${programTitle}_waitinglist_${joinRequest.length}.csv`
      );
    } catch (error) {
      setDownloadingJoinRequest(false);
      toast.error("Something went wrong");
      toast.error(error?.response?.data?.message);
    }
  };

  const deleteProgramHandler = async () => {
    setIsSending(true);
    try {
      await http.delete(`program/delete/${location.state.program.id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      toast.success("Program deleted");
      navigate(-1);
    } catch (error) {
      toast.error("Something went wrong");
      toast.error(error.response.data.message);
      setIsSending(false);
    }
  };
  const deactivateProgramHandler = async () => {
    try {
      await http.delete(`program/deactivate/${location.state.program.id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      toast.success("Program deactivated");
      navigate(-1);
    } catch (error) {
      toast.error("Something went wrong");
      setIsSending(false);
    }
  };
  const activateProgramHandler = async () => {
    try {
      await http.post(
        `program/activate/${location.state.program.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      toast.success("Program activated");
      navigate(-1);
    } catch (error) {
      toast.error("Something went wrong");
      setIsSending(false);
    }
  };
  const removeUserHandler = async () => {
    setLoading((prev) => ({
      ...prev,
      removingUser: true,
    }));
    try {
      await http.delete(
        `program/remove-user/${removeUser.UserProgram[0]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      toast.success("User removed activated");
      setLoading((prev) => ({
        ...prev,
        removingUser: false,
      }));
      navigate(-1);
    } catch (error) {
      toast.error("Something went wrong");
      setIsSending(false);
    }
  };
  const addManagerHandler = async () => {
    setLoading((prev) => ({
      ...prev,
      addManager: true,
    }));
    try {
      const res = await http.post(
        `/program/invite/manager/${location.state.program.id}`,
        {
          email: managerEmail,
        }
      );
      toast.success(res.data.message);
      setLoading((prev) => ({
        ...prev,
        addManager: false,
      }));
      setAddManager(false);
      setManagerEmail("");
    } catch (error) {
      setLoading((prev) => ({
        ...prev,
        addManager: false,
      }));
      toast.error(error.response.data.message);
      setIsSending(false);
    }
  };

  const approveRequest = async (userId) => {
    setLoading((prev) => ({
      ...prev,
      approval: true,
    }));
    try {
      await http.post(
        `program/join-request/approve`,
        {
          userId,
          programId: location.state.program.id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setLoading((prev) => ({
        ...prev,
        approval: false,
      }));
      window.location.reload();
      toast.success("Request approved");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setIsSending(false);
    }
  };
  // const approveAllRequest = async () => {
  //   setLoading((prev) => ({
  //     ...prev,
  //     approval: true,
  //   }));
  //   try {
  //     await http.post(
  //       `program/join-request/approve/${location.state.program.id}`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${auth.token}`,
  //         },
  //       }
  //     );
  //     setLoading((prev) => ({
  //       ...prev,
  //       approval: false,
  //     }));
  //     toast.success("Request approved");
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //     setIsSending(false);
  //   }
  // };

  const shareLink = async () => {
    const code = programInfo?.program?.link;

    const link = `${import.meta.env.VITE_FRONTEND_URL}/join/${code}`;

    await navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard");
    setTimeout(() => {
      window.open(link, "_blank");
    }, 1000);
  };

  return (
    <div>
      {deactivateProgram && (
        <Modal
          isOpen={deactivateProgram}
          onClose={() => setDeactivateProgram(false)}
          title="Deactivate Program"
          actions={
            <>
              <div className="flex items-center justify-end space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setDeactivateProgram(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={deactivateProgramHandler}
                  loading={isSending}
                >
                  Deactivate
                </Button>
              </div>
            </>
          }
        >
          <p>
            Are you sure you want to deactivate this program? All enrolled users
            will be unenrolled. You can re-activate the program later.
          </p>
        </Modal>
      )}
      {deleteProgram && (
        <Modal
          isOpen={deleteProgram}
          onClose={() => setDeleteProgram(false)}
          title="Delete Program"
          actions={
            <>
              <div className="flex items-center justify-end space-x-2">
                <Button
                  variant="text"
                  size="sm"
                  onClick={() => setDeleteProgram(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={deactivateProgramHandler}
                  loading={isSending}
                >
                  No, Deactivate
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={deleteProgramHandler}
                  loading={isSending}
                >
                  Yes, Delete
                </Button>
              </div>
            </>
          }
        >
          <p>
            Are you sure you want to delete this program? All enrolled users
            will be unenrolled. <br /> This action cannot be undone, and you
            deactivate the program instead.
          </p>
        </Modal>
      )}
      {removeUser && (
        <Modal
          isOpen={removeUser}
          onClose={() => setRemoveUser(null)}
          title={`Remove ${removeUser?.firstName} ${removeUser?.lastName}`}
          actions={
            <>
              <div className="flex items-center justify-end space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setRemoveUser(null)}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={removeUserHandler}
                  loading={loading.removingUser}
                >
                  Remove
                </Button>
              </div>
            </>
          }
        >
          <p>
            Are you sure you want to remove{" "}
            {`${removeUser?.firstName} ${removeUser?.lastName}`}? This action
            cannot be undone.
          </p>
        </Modal>
      )}
      {addManager && (
        <Modal
          isOpen={addManager}
          title={"Add Manager to Program"}
          onClose={() => setAddManager(false)}
          actions={
            <>
              <Button
                size="sm"
                onClick={addManagerHandler}
                loading={loading.addManager}
              >
                Add Manager
              </Button>
            </>
          }
        >
          <div>
            <TextInput
              label="Email"
              placeholder="Enter email"
              onChange={(e) => setManagerEmail(e.target.value)}
            />
            <div>
              <small className="text-gray-500">
                <span className="text-red-500">*</span> This user will be added
                as a manager to this program and will be able to manage the
                program.
              </small>
            </div>
          </div>
        </Modal>
      )}

      <div className="cursor-pointer mb-5" onClick={() => navigate(-1)}>
        <IoIosArrowRoundBack className="w-8 h-8" />
      </div>
      <TitleCards
        title={
          <div className="md:flex items-center justify-between">
            <div>
              {programInfo?.program?.title}
              <span>
                {programInfo?.program?.status === "DEACTIVATED" && (
                  <span className="text-red-500"> (Deactivated)</span>
                )}
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <Button
                size="xs"
                variant="ghost"
                onClick={() => {
                  navigate("/app/me/programs/create", {
                    state: { program: programInfo?.program },
                  });
                }}
              >
                Edit
              </Button>
              <Menu
                Icon={() => <HiOutlineDotsVertical className="text-gray-500" />}
              >
                <MenuItem
                  disabled={programInfo?.program?.role === "MANAGER"}
                  onClick={() => setAddManager(true)}
                >
                  <span>Add Manager</span>
                </MenuItem>
                {programInfo?.program?.status === "DEACTIVATED" ? (
                  <MenuItem onClick={activateProgramHandler}>
                    <span>Activate Program</span>
                  </MenuItem>
                ) : (
                  <MenuItem
                    variant="danger"
                    onClick={() => setDeactivateProgram(true)}
                  >
                    <span>Deactivate Program</span>
                  </MenuItem>
                )}
                <MenuItem
                  variant="danger"
                  disabled={programInfo?.program?.role === "MANAGER"}
                  onClick={() => setDeleteProgram(true)}
                >
                  <span>Delete Program</span>
                </MenuItem>
              </Menu>
              <button
                onClick={shareLink}
                className="border-none absolute top-[120px] right-3 bg-blue-500 text-white rounded-full p-3"
              >
                <IoIosShareAlt />
              </button>
            </div>
          </div>
        }
        subtitle={
          <>
            <span className="block">{programInfo?.message}</span>
            <span className="block">{programInfo?.todayMessage}</span>
            <div className="flex items-center justify-between">
              <div className="flex flex-wrap items-center justify-start gap-2 mt-5">
                <span className="text-sm font-normal py-1 px-3 bg-gray-500 rounded-full text-white">
                  {programInfo.userEnrolledCount -
                    programInfo.unenrolledUserCount -
                    programInfo.removedUserCount}{" "}
                  Enrolled Users
                </span>
                <span className="text-sm font-normal py-1 px-3 bg-green-500 rounded-full text-white">
                  {programInfo.activeUsersCount} Active Users
                </span>
                <span className="text-sm font-normal py-1 px-3 bg-red-500 rounded-full text-white">
                  {programInfo.unenrolledUserCount} Unenrolled Users
                </span>
                <span className="text-sm font-normal py-1 px-3 bg-red-500 rounded-full text-white">
                  {programInfo.removedUserCount} Removed User
                </span>
              </div>
              <div className="uppercase font-mono">
                {programInfo?.program?.role}
              </div>
            </div>
          </>
        }
      />
      <div className="flex flex-col gap-10 mt-10">
        <div className="w-full">
          <TitleCards
            title={
              <>
                <div className="flex justify-between">
                  <p>Activities</p>
                  {/* <Button variant="outline" size="sm">
                    View all
                  </Button> */}
                </div>
              </>
            }
            subtitle="Activities progress"
          >
            <div className="flex flex-row flex-wrap gap-5">
              {programInfo?.activityPerDay?.map((activity) => (
                <>
                  <div
                    className="flex group flex-col mb-2 cursor-pointer hover:bg-gray-100 py-3 px-5 rounded duration-150 border border-gray-200"
                    key={activity.id}
                    onClick={() => {
                      navigate(
                        `/app/me/programs/manage/view-completed/${
                          new Date(activity.date).toISOString().split("T")[0]
                        }`,
                        {
                          state: {
                            ...activity,
                            programId: location.state.program.id,
                            ISODate: new Date(activity.date).toISOString(),
                          },
                        }
                      );
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium text-gray-600">
                          Day {activity.day} |{" "}
                          {new Date(activity.date).toDateString()}
                        </h3>
                        <p className="text-sm text-gray-500 mt-0">
                          {activity.completed}{" "}
                          {activity.completed > 1 ? "people" : "person"}{" "}
                          completed / {activity.void} void
                        </p>
                      </div>
                      <div className="ml-4 group-hover:opacity-100 opacity-0 -translate-x-7 group-hover:translate-x-0 duration-150">
                        <HiOutlineChevronRight />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </TitleCards>
        </div>
        <div className="w-full">
          <TitleCards
            title={
              <>
                <div className="flex items-center justify-between">
                  <div>{`${
                    enrolledUser?.count - enrolledUser.unenrolledCount
                  } users in Program (${
                    enrolledUser.unenrolledCount
                  } in others)`}</div>
                  <div>
                    <Button
                      variant="ghost"
                      size="xs"
                      onClick={downloadUsers}
                      loading={downloadingUsers}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </>
            }
            subtitle="Total number of users in this program. 'Others' include removed and self unenrolled users."
          >
            <div className="flex flex-wrap gap-10">
              {enrolledUser?.users?.map((user) => (
                <div
                  className="flex flex-col mb-2 bg-gray-100 px-6 py-2 rounded shadow-sm w-3/12"
                  key={user.email}
                >
                  <div>
                    <h3 className="font-medium text-gray-600">
                      {user.lastName} {user.firstName}
                    </h3>
                    <p className="text-sm text-gray-500">{user.email}</p>
                    <div className="mt-3 flex space-x-4">
                      <span className="text-sm">
                        {userStatus(user.UserProgram[0].status)}
                      </span>
                    </div>
                    {userStatus(user.UserProgram[0].status) === "Enrolled" && (
                      <div className="mt-8 flex items-start justify-end">
                        <Button
                          variant="danger"
                          size="xs"
                          onClick={() => {
                            setRemoveUser(user);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </TitleCards>
        </div>
        {!programInfo?.program?.isPublic && (
          <div className="w-full">
            <TitleCards
              title={
                <>
                  <div className="flex items-center justify-between">
                    <div>{`${joinRequest?.length} request to join`}</div>
                    {joinRequest.length > 0 && (
                      <Button
                        variant="ghost"
                        size="xs"
                        onClick={downloadJoinRequest}
                        loading={downloadingJoinRequest}
                      >
                        Download
                      </Button>
                    )}
                  </div>
                </>
              }
              subtitle="Users who requested to join this program"
            >
              <div className="flex flex-wrap gap-10">
                {joinRequest?.map((user) => (
                  <div
                    className="flex flex-col mb-2 bg-gray-100 px-6 py-2 rounded shadow-sm w-3/12"
                    key={user.email}
                  >
                    <div>
                      <h3 className="font-medium text-gray-600">
                        {user.user.lastName} {user.user.firstName}
                      </h3>
                      <p className="text-sm text-gray-500">{user.user.email}</p>
                      <div className="mt-4">
                        <Button
                          variant="outline"
                          size="xs"
                          loading={loading.approval}
                          onClick={() => approveRequest(user.user.id)}
                        >
                          Accept
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TitleCards>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageProgramContent;
