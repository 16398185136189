import {
  AiFillProject,
  AiFillSetting,
  AiOutlineClose,
  AiOutlineLogout,
  AiOutlinePlus,
} from "react-icons/ai";
import { LuLayoutDashboard } from "react-icons/lu";
import SidebarItem from "./SidebarItem";
import useAuth from "../../protector/AuthService";
import { useNavigate } from "react-router-dom";
// import { useEffect } from "react";

const Sidebar = ({ openMobile, setOpenMobile }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div
      id="sidebar"
      className={`flex-shrink-0 h-full z-[10] ${
        openMobile ? "fixed top-0 left-0 shadow-2xl" : "hidden lg:block"
      }`}
    >
      <div className="flex flex-col items-center justify-between px-6 py-10 h-full bg-gray-50 border-r-2 border-gray-200">
        {openMobile && (
          <div onClick={() => setOpenMobile(false)} className="p-4 mb-5">
            <AiOutlineClose />
          </div>
        )}
        <div>
          <div className="font-bold text-4xl text-blue-500">D</div>
        </div>
        <div className="flex h-full items-center justify-start py-10 space-y-7 flex-col">
          <SidebarItem
            Icon={AiOutlinePlus}
            title="Create Program"
            type="primary"
            href="/app/me/programs/create"
            closeSidebar={() => setOpenMobile(false)}
          />
          <SidebarItem
            Icon={LuLayoutDashboard}
            title="Dashboard"
            type="link"
            href="/app/me"
            closeSidebar={() => setOpenMobile(false)}
          />
          <SidebarItem
            Icon={AiFillProject}
            title="Programs"
            type="link"
            href="/app/me/programs/view"
            closeSidebar={() => setOpenMobile(false)}
          />
          <SidebarItem
            Icon={AiFillSetting}
            title="Settings"
            type="link"
            href="/app/me/settings"
            closeSidebar={() => setOpenMobile(false)}
          />
        </div>
        <div>
          <SidebarItem
            onClick={() =>
              auth.logout(() => {
                navigate("/login");
              })
            }
            Icon={AiOutlineLogout}
            title="Logout"
            type="destructive"
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
