import React, { useEffect } from "react";
import { AiOutlineClose, AiOutlineFile } from "react-icons/ai";

import { toast } from "react-toastify";
import http from "../api/http";
import { generateSources } from "../utils/generateSources";
import { linkify } from "../utils/linkify";
import { Button } from "./Buttons";
import Drawer from "./Drawer/Drawer";
import FileInput from "./Inputs/FIleInput";
import TextAreaFormatted from "./Inputs/TextAreaFormatted";
import VideoJS from "./VideoJS";

const ActivitySubmission = ({
  activity,
  setActivity,
  isModalOpen,
  setIsModalOpen,
  refetchDueActivities,
}) => {
  const [submission, setSubmission] = React.useState({
    submission: "",
    images: [],
    files: [],
  });

  const [submitting, setSubmitting] = React.useState(false);

  useEffect(() => {
    if (activity) {
      setSubmission({
        submission: activity?.submission,
        images: activity?.images || [],
        files: activity?.files || [],
      });
    }
  }, [activity]);

  const submitActivity = async () => {
    setSubmitting(true);

    const formData = new FormData();
    const { submission: submissionText, files, images } = submission;

    const totalFiles = files.length + images.length;

    if (totalFiles > 10) {
      toast.error("You can only upload up to 10 files");
      setSubmitting(false);
      return;
    }

    formData.append("submission", submissionText || "DONE");

    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append("files", file);
      });
    }

    if (images.length > 0) {
      formData.append("images", JSON.stringify(images));
    }

    formData.append("activityId", activity.activityId);
    formData.append("userProgramId", activity.userProgramId);

    try {
      await http.post(`/program/activity/submit`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Activity submitted successfully");
      setSubmitting(false);
      setIsModalOpen(false);
      setActivity(null);
      setSubmission({
        submission: "",
        images: [],
        files: [],
      });
      await refetchDueActivities();
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Unable to submit activity, please try again later"
      );
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Drawer
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setActivity(null);
          setSubmission({
            submission: "",
            images: [],
            files: [],
          });
          setActivity(null);
        }}
        title={`Submit ${activity.title}`}
        actions={
          <>
            <Button
              variant="solid"
              size="sm"
              loading={submitting}
              onClick={submitActivity}
              disabled={activity?.submissionRequired && !submission.submission}
            >
              {activity?.submissionRequired ? "Submit" : "Mark as done"}
            </Button>
          </>
        }
      >
        <div className="">
          <h3 className="font-medium text-lg">{activity?.title}</h3>
          <div className="text-gray-400 mt-4">
            <span className="uppercase text-xs block text-gray-600">
              description
            </span>
            <div
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: linkify(activity?.description)[0],
              }}
            ></div>
          </div>
          <div className="text-gray-400 mt-4">
            <span className="uppercase text-xs block text-gray-600">
              Instructions
            </span>
            <div
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: linkify(activity?.instructions)[0],
              }}
            ></div>
          </div>
          <div className="my-5">
            {activity.ActivityVideo && activity.ActivityVideo.length > 0 && (
              <>
                <VideoJS
                  options={{
                    autoplay: false,
                    controls: true,
                    responsive: true,
                    fluid: true,
                    sources: generateSources(activity.ActivityVideo[0]),
                  }}
                />
              </>
            )}
          </div>
          <div className="mt-5">
            <TextAreaFormatted
              label="Type here..."
              value={submission.submission}
              rows={5}
              onChange={(value) =>
                setSubmission((prev) => {
                  return { ...prev, submission: value };
                })
              }
            />
            {activity?.submissionRequired && !submission.submission && (
              <p className="text-xs text-red-500 mt-2">
                This activity requires submission
              </p>
            )}
          </div>

          <div className="mt-5">
            <div className="overflow-hidden">
              {submission.files.length > 0 && (
                <div className="flex items-center justify-start gap-2 mt-2 space-x-2 overflow-x-scroll py-6">
                  {submission?.files?.map((file, index) => {
                    const size = file.size / 1024 / 1024;
                    const format = file.type.split("/")[1];
                    let image = (
                      <AiOutlineFile className="w-full h-full object-cover rounded" />
                    );
                    if (
                      format === "png" ||
                      format === "jpg" ||
                      format === "jpeg"
                    ) {
                      const link = URL.createObjectURL(file);
                      image = (
                        <img
                          src={link}
                          alt=""
                          className="w-full h-full object-cover rounded"
                        />
                      );
                    }
                    const moreThan5MB = size > 5;
                    return (
                      <div
                        className={`w-20 h-20 ${
                          moreThan5MB ? "border-red-400" : "border-gray-300"
                        } border-2 rounded relative cursor-pointer flex-shrink-0`}
                        key={index}
                        onClick={() => {
                          setSubmission((prev) => ({
                            ...prev,
                            files: prev.files.filter(
                              (file) => file !== prev.files[index]
                            ),
                          }));
                        }}
                      >
                        <span className="absolute -top-3 -right-3 rounded-full bg-red-500 p-2">
                          <AiOutlineClose className="text-white" />
                        </span>
                        {image}
                        <span className="text-[10px] text-gray-400 w-max">
                          {format.toUpperCase()} - {size.toFixed(2)}MB
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
              {submission.images.length > 0 && (
                <div className="mt-5">
                  <span className="text-xs text-gray-400 uppercase">
                    PREVIOUS UPLOADS
                  </span>
                  <div className="overflow-hidden">
                    <div className="flex items-center justify-start gap-2 mt-2 space-x-2 overflow-x-scroll py-6">
                      {submission?.images?.map((file) => {
                        const format = file.fileType;
                        let image = (
                          <AiOutlineFile className="w-full h-full object-cover rounded" />
                        );
                        if (format === "IMAGE") {
                          image = (
                            <img
                              src={file.fileUrl}
                              alt=""
                              className="w-full h-full object-cover rounded"
                            />
                          );
                        }

                        return (
                          <div
                            className="flex-shrink-0 w-20 h-20 border-gray-300 border rounded relative cursor-pointer"
                            key={file.id}
                            onClick={() => {
                              setSubmission((prev) => ({
                                ...prev,
                                images: prev.images.filter(
                                  (image) => image.id !== file.id
                                ),
                              }));
                            }}
                          >
                            <span className="absolute -top-3 -right-3 rounded-full bg-red-500 p-2">
                              <AiOutlineClose className="text-white" />
                            </span>
                            {image}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <FileInput
              label={
                <>
                  Upload files{" "}
                  {activity?.attachmentRequired ? (
                    <span className="text-red-500">(Required)</span>
                  ) : (
                    "(Optional)"
                  )}{" "}
                </>
              }
              max={10}
              multiple={true}
              onChange={(event) =>
                setSubmission((prev) => ({
                  ...prev,
                  files: [...prev.files, ...event.target.files],
                }))
              }
            />
            <p className="text-sm text-gray-400 mt-1">
              <span className="text-gray-400">Note: </span> You can upload up to
              10 files JPG, PNG, PDF, DOCX, CSV, XLSX allowed. Max file size is
              5MB
            </p>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ActivitySubmission;
