import { useId, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export const PasswordInput = ({ placeholder, label, error, ...props }) => {
  const id = useId();
  const [isPassword, setIsPassword] = useState(true);

  return (
    <div className="">
      <label
        className="block text-gray-500 font-medium cursor-pointer"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="flex items-center justify-start border-2 border-gray-400 rounded mt-1 placeholder:text-gray-400 text-gray-700 focus-within:border-gray-500 duration-150">
        <input
          id={id}
          type={isPassword ? "password" : "text"}
          placeholder={placeholder}
          className="px-3 py-3 rounded w-full outline-none"
          {...props}
        />
        <button
          type="button"
          className="px-2"
          onClick={() => {
            setIsPassword(!isPassword);
          }}
        >
          {isPassword ? (
            <AiFillEyeInvisible className="w-6 h-6 text-gray-400" />
          ) : (
            <AiFillEye className="w-6 h-6 text-gray-400" />
          )}
        </button>
      </div>
      {error && (
        <div>
          <p className="text-red-400 text-sm mt-1">{error}</p>
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
