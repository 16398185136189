import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

const menuItem = cva(
  "block px-4 py-2  text-left hover:bg-gray-100 w-ful disabled:cursor-not-allowed w-full",
  {
    variants: {
      variant: {
        normal: ["text-gray-500 disabled:text-gray-300"],
        danger: ["text-red-500 disabled:text-red-300"],
      },
    },
    defaultVariants: {
      variant: "normal",
    }
  }
);

const MenuItem = ({ children, ...props }) => {
  return (
    <li className="w-full">
      <button
        type="button"
        className={twMerge(
          menuItem({
            variant: props.variant,
          })
        )}
        {...props}
      >
        {children}
      </button>
    </li>
  );
};

export default MenuItem;
