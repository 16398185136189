import { dueTimes } from "../../utils/dueTimes";
import { truncate } from "../../utils/truncate";
import { Button } from "../Buttons";
import dailyDO from "../../assets/dailydo.ai.png";

const DueActivities = ({
  activityTitle,
  dueDate,
  activityInstruction,
  programTitle,
  isVisible,
  action,
  image = "",
}) => {
  const d = dueTimes(dueDate);

  return (
    <div>
      <div>
        <div
          className={`relative shadow cursor-pointer ${
            isVisible
              ? "opacity-100 cursor-pointer"
              : "opacity-50 cursor-not-allowed"
          }`}
          onClick={isVisible ? action : null}
        >
          <span className="absolute top-3 right-3 bg-blue-300 px-4 py-1 rounded-full  text-sm">
            <span className="text-black text-sm">
              Due in {d} day{d > 1 && "s"}
            </span>
          </span>

          <div className="h-[287px] rounded-sm overflow-hidden">
            <img
              src={image ? image : dailyDO}
              className="rounded-md h-full w-full object-cover"
            />
          </div>
        </div>
        <div>
          <h4 className="uppercase text-xs  mt-3 text-gray-500">
            {truncate(programTitle, 20)}
          </h4>
          <h3 className="font-medium text-lg">{truncate(activityTitle, 20)}</h3>
          <p className="text-gray-400 mt-1">
            {truncate(activityInstruction, 100)}
          </p>
          <div className="mt-5 grid grid-cols-2">
            <div></div>
            <Button
              variant="outline"
              size="xs"
              onClick={action}
              disabled={!isVisible}
              title={`
              Wait until the due date to submit this activity
            `}
            >
              View &rarr;{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DueActivities;
