import http from "../../../api/http";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Buttons/Button";
import TitleCards from "../../../components/Cards/TitleCards";
// import TextInput from "../../../components/Inputs/TextInput";
import { useEffect, useState } from "react";
import useAuth from "../../../protector/AuthService";
import { toast } from "react-toastify";
import EnrolledProgram from "../../../components/Programs/EnrolledProgram";
import CreatedPrograms from "../../../components/Programs/CreatedProgram";
import Modal from "../../../components/Modal/Modal";
import { dueTimes, passedDays } from "../../../utils/dueTimes";
import dayjs from "dayjs";

const getPrograms = async (token) => {
  try {
    const req = await http.get("program/my-programs", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return [req, null];
  } catch (error) {
    return [null, error];
  }
};

const getProgramsEnrolled = async (token) => {
  try {
    const req = await http.get("program/enrolled-programs", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return [req, null];
  } catch (error) {
    return [null, error];
  }
};

const appendWithTitle = ({ startDate, endDate }) => {
  const today = new Date(dayjs(new Date()).format("YYYY-MM-DD")).toISOString();
  const start = new Date(dayjs(startDate).format("YYYY-MM-DD")).toISOString();
  const end = new Date(dayjs(endDate).format("YYYY-MM-DD")).toISOString();

  if (start > today) {
    return `(Starts in ${dueTimes(new Date(start))} days)`;
  }

  if (start === today) {
    return `(Starts Today)`;
  }

  if (today > start && today < end) {
    return `(In program, ${passedDays(new Date(start))} days in)`;
  }

  if (today > end) {
    return `(Program ended, ${passedDays(new Date(end))} days ago)`;
  }
};

const isProgramEnded = ({ endDate }) => {
  const today = new Date().toISOString().split("T")[0];
  const end = new Date(endDate).toISOString().split("T")[0];

  if (today > end) {
    return true;
  }

  return false;
};

const ViewPrograms = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [programs, setProgram] = useState([]);
  const [enrolledProgram, setEnrolledPrograms] = useState({});
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [confirmedUnroll, setConfirmedUnroll] = useState(false);
  const [unenrolling, setUnenrolling] = useState(false);
  const [loading, setLoading] = useState({
    programs: false,
    enrolledPrograms: false,
  });
  const [revalidate, setRevalidate] = useState(true);

  useEffect(() => {
    if (revalidate) {
      setLoading((prev) => ({
        programs: true,
        enrolledPrograms: true,
      }));
      getPrograms(auth.token).then(([req, err]) => {
        if (req) {
          setProgram(req.data);
          setLoading((prev) => ({
            ...prev,
            programs: false,
          }));
        } else {
          setLoading((prev) => ({
            ...prev,
            programs: false,
          }));
          toast.error("Unable to fetch programs...");
        }
      });
      getProgramsEnrolled(auth.token).then(([req, err]) => {
        if (req) {
          setEnrolledPrograms(req.data);
          setLoading((prev) => ({
            ...prev,
            enrolledPrograms: false,
          }));
        } else {
          toast.error("Unable to fetch programs...");
          setLoading((prev) => ({
            ...prev,
            enrolledPrograms: false,
          }));
        }
      });
      setRevalidate(false);
    }
  }, [revalidate]);

  const unenroll = async (userProgramId) => {
    setUnenrolling(true);
    try {
      await http.delete(`program/user/unenroll/${userProgramId}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      toast.success("Unenrolled from program");
      setUnenrolling(false);
      setConfirmedUnroll(false);
      setSelectedProgram(null);
      setRevalidate(true);
    } catch (error) {
      toast.error("Unable to unenroll from program");
      setUnenrolling(false);
    }
  };

  const reenroll = async (userProgramId) => {
    setUnenrolling(true);
    try {
      await http.post(
        `program/user/enroll/${userProgramId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      toast.success("Enrolled to the program");
      setUnenrolling(false);
      setConfirmedUnroll(false);
      setSelectedProgram(null);
      setRevalidate(true);
    } catch (error) {
      toast.error("Unable to enroll to the program");
      setUnenrolling(false);
    }
  };

  return (
    <div>
      {selectedProgram && (
        <Modal
          actions={
            <>
              <div className="flex items-center justify-end">
                {selectedProgram.UserProgram[0]?.status ===
                  "UNENROLLED_PROGRAM_DELETED" ||
                selectedProgram.UserProgram[0]?.status ===
                  "UNENROLLED_USER_REMOVED" ||
                selectedProgram.UserProgram[0]?.status ===
                  "UNENROLLED_PROGRAM_DEACTIVATED" ? (
                  <></>
                ) : selectedProgram.status === "PUBLISHED" &&
                  selectedProgram?.UserProgram[0]?.status ===
                    "ENROLLED_ACTIVE" ? (
                  <>
                    <Button
                      disabled={isProgramEnded({
                        endDate: selectedProgram.endDate,
                      })}
                      onClick={() => setConfirmedUnroll(true)}
                      variant="danger"
                      size="sm"
                    >
                      Unenroll
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="solid"
                      size="sm"
                      loading={unenrolling}
                      onClick={() =>
                        reenroll(selectedProgram?.UserProgram[0]?.id)
                      }
                    >
                      Re-enroll
                    </Button>
                  </>
                )}
              </div>
            </>
          }
          title={
            <>
              <span>
                {selectedProgram.title}{" "}
                <span className="text-base font-normal">
                  {selectedProgram.status === "DEACTIVATED"
                    ? "(Deactivated)"
                    : appendWithTitle({
                        startDate: selectedProgram.startDate,
                        endDate: selectedProgram.endDate,
                      })}
                </span>
              </span>
            </>
          }
          isOpen={selectedProgram}
          onClose={() => {
            setSelectedProgram(null);
          }}
        >
          <div className="space-y-8">
            <div>
              <h3 className="text-sm uppercase text-gray-500 font-semibold">
                {" "}
                Duration
              </h3>
              <p className="text-base mt-1 text-gray-600">
                Runs from{" "}
                <span className="font-bold">
                  {new Date(selectedProgram.startDate).toDateString()}
                </span>{" "}
                to{" "}
                <span className="font-bold">
                  {new Date(selectedProgram.endDate).toDateString()}
                </span>
              </p>
            </div>
            {selectedProgram.tagline && (
              <div>
                <h3 className="text-sm uppercase text-gray-500 font-semibold">
                  {" "}
                  Program Tagline
                </h3>
                <p className="text-base mt-1 text-gray-600">
                  {selectedProgram.tagline || "No tagline provided"}
                </p>
              </div>
            )}
            {selectedProgram.description && (
              <div>
                <h3 className="text-sm uppercase text-gray-500 font-semibold">
                  {" "}
                  Program Description
                </h3>
                <p className="text-base mt-1 text-gray-600">
                  {selectedProgram.description}
                </p>
              </div>
            )}
            {selectedProgram.aim && (
              <div>
                <h3 className="text-sm uppercase text-gray-500 font-semibold">
                  {" "}
                  Program Aim
                </h3>
                <p className="text-base mt-1 text-gray-600">
                  {selectedProgram.aim || "No aim provided"}
                </p>
              </div>
            )}
            <div className="grid grid-cols-2">
              <div>
                <p className="text-base mt-1 text-gray-600">
                  {selectedProgram.activity.length} Activities
                </p>
              </div>
              {/* <div>
                <p className="text-base mt-1 text-gray-600">
                  {selectedProgram._count.UserProgram}{" "}
                  {selectedProgram._count.UserProgram > 1 ? "People" : "Person"}{" "}
                  Enrolled
                </p>
              </div> */}
            </div>
          </div>
        </Modal>
      )}
      {confirmedUnroll && (
        <Modal
          title="Unenroll from program?"
          isOpen={confirmedUnroll}
          onClose={() => {
            setConfirmedUnroll(false);
          }}
          actions={
            <>
              <div className="flex items-center justify-end">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    setConfirmedUnroll(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  loading={unenrolling}
                  onClick={() => {
                    unenroll(selectedProgram?.UserProgram[0]?.id);
                  }}
                >
                  Unenroll
                </Button>
              </div>
            </>
          }
        >
          You are about to unenroll from the program{" "}
          <span className="font-bold">{selectedProgram.title}</span>. You can
          re-enroll at any time, if the program creator allows it.
        </Modal>
      )}

      <div className="flex gap-10">
        <div className="md:w-8/12 flex flex-col gap-10">
          {programs.length > 0 && (
            <TitleCards
              title={`Manage Programs (${programs.length}) `}
              subtitle="View all your programs"
              loading={loading.programs}
              noData={programs.length === 0}
              noDataMessage="You have no programs"
            >
              <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
                {programs.map((program) => (
                  <CreatedPrograms
                    key={program.id}
                    program={program}
                    onEdit={() => {
                      navigate("/app/me/programs/create", {
                        state: { program },
                      });
                    }}
                    onView={() => {
                      navigate("/app/me/programs/manage/content", {
                        state: { program },
                      });
                    }}
                    isManage={program.creator.id !== auth.user.id}
                  />
                ))}
              </div>
            </TitleCards>
          )}

          <TitleCards
            title={`Enrolled Programs (${enrolledProgram?.programEnrolled?.length ? enrolledProgram?.programEnrolled?.length : ''})`}
            subtitle="View all the programs you are currently enrolled in"
            loading={loading.enrolledPrograms}
            noData={enrolledProgram?.programEnrolled?.length === 0}
            noDataMessage="You have no enrolled programs"
          >
            <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
              {enrolledProgram?.programEnrolled?.map((program) => (
                <EnrolledProgram
                  key={program.id}
                  program={program}
                  onClick={() => {
                    setSelectedProgram(program);
                  }}
                />
              ))}
            </div>
          </TitleCards>

          {enrolledProgram?.programUnenrolled?.length > 0 && (
            <TitleCards
              title={`Unenrolled Programs (${enrolledProgram?.programUnenrolled?.length})`}
              subtitle="View all the programs you are unenrolled from and you ca re-enrolled in"
              loading={loading.enrolledPrograms}
              noData={enrolledProgram?.programUnenrolled?.length === 0}
              noDataMessage="You have no unenrolled programs"
            >
              <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
                {enrolledProgram?.programUnenrolled?.map((program) => (
                  <EnrolledProgram
                    key={program.id}
                    program={program}
                    onClick={() => {
                      setSelectedProgram(program);
                    }}
                  />
                ))}
              </div>
            </TitleCards>
          )}

          {enrolledProgram?.othersProgram?.length > 0 && (
            <TitleCards
              title={`Other Programs (${enrolledProgram?.othersProgram?.length})`}
              subtitle="Other programs include deleted programs, deactivated programs programs you were removed from"
              loading={loading.enrolledPrograms}
              noData={enrolledProgram?.othersProgram?.length === 0}
              noDataMessage="You have no other programs"
            >
              <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
                {enrolledProgram?.othersProgram?.map((program) => (
                  <EnrolledProgram
                    key={program.id}
                    program={program}
                    status={program.UserProgram[0]?.status}
                    showAction={false}
                    onClick={() => {
                      setSelectedProgram(program);
                    }}
                  />
                ))}
              </div>
            </TitleCards>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewPrograms;
