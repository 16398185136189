import { LoadingSvg } from "../Buttons";
import Card from "./Card";

import NoDataImage from "../../assets/images/no_data.svg";
import DueActivityLoader from "../loaders/DueActivityLoader.jsx";

const LoadingState = () => (
  <div className="flex justify-center items-center py-5">
    <LoadingSvg />
  </div>
);

const NoDataState = ({ noDataMessage }) => (
  <div className="flex flex-col justify-center items-center py-10">
    <img src={NoDataImage} alt="No Data" className="w-52" />
    <p className="text-gray-400 text-sm md:text-base mt-3">{noDataMessage}</p>
  </div>
);

const TitleCards = ({
  loading = false,
  noData = false,
  noDataMessage = "No Data Found",
  title,
  subtitle,
  className,
  children,
}) => {
  return (
    <Card className={className}>
      <h2 className="font-medium lg:text-xl text-lg">{title}</h2>
      <div className="text-gray-400 text-sm md:text-base mt-1">{subtitle}</div>
      {loading ? (
        <DueActivityLoader />
      ) : noData ? (
        <NoDataState noDataMessage={noDataMessage} />
      ) : (
        children && <div className="mt-8">{children}</div>
      )}
    </Card>
  );
};

export default TitleCards;
