import { toast } from "react-toastify";
import { IoIosShareAlt } from "react-icons/io";
import { truncate } from "../../utils/truncate";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";
import dailyDO from "../../assets/dailydo.ai.png";

const CreatedPrograms = ({ program, onEdit, onView }) => {
  const shareLink = async () => {
    const link = `${import.meta.env.VITE_FRONTEND_URL}/join/${program.link}`;

    await navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard");
    setTimeout(() => {
      window.open(link, "_blank");
    }, 1000);
  };

  return (
    <div>
      <div className="cursor-pointer">
        <div>
          <div className="relative shadow">
            {program.status !== "DEACTIVATED" && (
              <button
                onClick={shareLink}
                className="border-none absolute top-3 right-3 bg-blue-500 text-white rounded-full p-3"
              >
                <IoIosShareAlt />
              </button>
            )}
            <div
              onClick={onView}
              className="h-[287px] rounded-sm overflow-hidden"
            >
              <img
                src={program.image || dailyDO}
                className="rounded-md h-full w-full object-cover"
              />
            </div>
          </div>
          <div>
            <div className="mt-3 flex items-center justify-between">
              <h3 className="font-medium text-xl">
                {truncate(program?.title, 20)}
              </h3>
              <div>
                <Menu Icon={() => <HiOutlineDotsVertical />}>
                  <MenuItem onClick={onView}>View</MenuItem>
                  <MenuItem onClick={onEdit}>Edit</MenuItem>
                </Menu>
              </div>
            </div>
            <p className="text-gray-400 mt-1 text-sm">
              {truncate(program?.description, 100)}
            </p>
            {/* <div className="mt-5">
              <Button variant="outline" size="sm" fullWidth onClick={onClick}>
                View
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatedPrograms;
