import { cva } from "class-variance-authority";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const sidebar = cva("group relative duration-150 p-3 rounded-md shadow", {
  variants: {
    type: {
      primary: ["bg-blue-700", "hover:bg-blue-800", "text-white"],
      link: ["bg-blue-50", "hover:bg-blue-100", "text-slate-700"],
      destructive: [
        "bg-red-50",
        "hover:bg-red-500",
        "text-red-500",
        "hover:text-white",
        "shadow-sm",
      ],
    },
    active: {
      true: [""],
    },
  },
  defaultVariants: {
    type: "link",
  },
});

const sidebarItemIcon = cva("", {
  variants: {
    type: {
      primary: ["w-6 h-6"],
      link: ["w-5 h-5"],
      destructive: ["w-5 h-5"],
    },
  },
});

const SidebarItem = ({ Icon, title, type, href, onClick, closeSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = href === location.pathname;

  const handleClick = () => {
    if (href) {
      navigate(href);
      closeSidebar && closeSidebar();
    }
  };

  return (
    <div className="flex items-center flex-col">
      <button
        onClick={onClick || handleClick}
        className={twMerge(
          sidebar({
            type,
            active: isActive,
          })
        )}
      >
        <Icon className={twMerge(sidebarItemIcon({ type }))} />
        {isActive && type === "link" && (
          <span className="absolute bottom-0 w-[80%] h-2 bg-blue-700 left-[50%] translate-x-[-50%] shadow-md rounded-full"></span>
        )}
        <div className="group-hover:visible invisible absolute flex left-[60px] top-[50%] translate-y-[-50%] bg-gray-700 text-white z-10 py-3 px-4 rounded">
          <span className="max-w-[26rem] w-[max-content] text-sm">{title}</span>
        </div>
      </button>
      <div className="text-xs mt-2 text-gray-400 max-w-min text-center lg:hidden">{title}</div>
    </div>
  );
};

export default SidebarItem;
