import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/Buttons";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen w-full bg-blue-50">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-500">404</h1>
        <p className="text-gray-500">
          The page you are looking for does not exist.
        </p>
        <div className="mt-10">
          <Link to="/" className="text-blue-500">
            <Button variant="text" size="sm">
              Go Home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
