import { useCallback, useEffect, useState } from "react";
import http from "../../../../api/http";
import TitleCards from "../../../../components/Cards/TitleCards";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import useAuth from "../../../../protector/AuthService";
import { Button } from "../../../../components/Buttons";
import Modal from "../../../../components/Modal/Modal";
import { TextAreaInput } from "../../../../components/Inputs";
import { toast } from "react-toastify";
import {
  AiOutlineDownload,
  AiOutlineFile,
  AiOutlineZoomIn,
} from "react-icons/ai";
import { linkify } from "../../../../utils/linkify.js";
import { useQuery } from "@tanstack/react-query";
import { HiOutlineChevronRight } from "react-icons/hi";
import { truncate } from "../../../../utils/truncate.js";
import { passedDaysFromTo } from "../../../../utils/dueTimes.js";
import dayjs from "dayjs";

const ViewCompleted = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const [isSending, setIsSending] = useState(false);
  const [remark, setRemark] = useState("");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [openImageFull, setOpenImageFull] = useState(false);

  const info = location.state;

  const { data: submissions } = useQuery({
    queryKey: ["getComplete", info.programId, info.date],
    queryFn: async () =>
      (
        await http.get(`program/submitted-per-day/${info.programId}`, {
          params: {
            date: info.date,
          },
        })
      ).data,
  });

  const { data: activityPerDay, isLoading: loadingActivityPerDay } = useQuery({
    queryKey: ["getActivityPerDay", info.programId],
    queryFn: async () =>
      (await http.get(`program/activity/per-day/${info.programId}`)).data,
  });

  const sendRemark = async () => {
    setIsSending(true);
    try {
      await http.post(`/program/activity/remark/${selectedActivity.id}`, {
        message: remark,
        userId: selectedActivity.userProgram.user.id,
        programId: selectedActivity.userProgram.program.id,
        activityId: selectedActivity.activity.id,
      });
      toast.success("Remark sent");
      window.location.reload();
    } catch (error) {
      toast.error("Something went wrong");
      setIsSending(false);
    }
  };

  useEffect(() => {
    const info = location.state;
    if (!info.programId) navigate(-1);
  }, [location.state]);

  const triggerDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {selectedActivity && (
        <Modal
          title={`Give remark`}
          isOpen={selectedActivity}
          onClose={() => {
            setSelectedActivity(null);
          }}
          actions={
            <div className="flex items-center justify-end">
              <Button
                variant="solid"
                size="sm"
                loading={isSending}
                onClick={sendRemark}
              >
                {selectedActivity.response
                  ? "Give another remark"
                  : "Give remark"}
              </Button>
            </div>
          }
        >
          <div>
            <div
              className="py-5 text-xl"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: linkify(selectedActivity.submission)[0],
              }}
            ></div>
            {selectedActivity.imagesList && selectedImage && (
              <div>
                <div className="h-[400px] relative">
                  <div
                    className="absolute top-5 right-5 bg-white/50 cursor-pointer p-3 rounded-full"
                    onClick={() => {
                      selectedImage.fileType === "IMAGE"
                        ? setOpenImageFull(true)
                        : triggerDownload(
                            selectedImage.fileUrl,
                            `${selectedActivity.userProgram.user.firstName}-${selectedActivity}`
                          );
                    }}
                  >
                    {selectedImage.fileType === "IMAGE" ? (
                      <AiOutlineZoomIn className="w-6 h-6" />
                    ) : (
                      <AiOutlineDownload className="w-6 h-6" />
                    )}
                  </div>
                  {selectedImage.fileType === "IMAGE" ? (
                    <img
                      src={selectedImage.fileUrl}
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <AiOutlineFile className="w-full h-full object-cover rounded" />
                  )}
                </div>
                <div>
                  <div className="flex items-center justify-start gap-2 mt-2 space-x-2 overflow-x-scroll py-6">
                    {selectedActivity?.imagesList?.map((file) => {
                      let image = (
                        <AiOutlineFile className="w-full h-full object-cover rounded" />
                      );
                      if (file.fileType === "IMAGE") {
                        image = (
                          <img
                            src={file.fileUrl}
                            alt=""
                            className="w-full h-full object-cover rounded"
                          />
                        );
                      }
                      return (
                        <div key={file.id}>
                          <div
                            className={`w-20 h-20 border-2 rounded relative cursor-pointer flex-shrink-0 ${
                              selectedImage.id === file.id
                                ? "border-gray-400"
                                : ""
                            }`}
                            key={file.id}
                            onClick={() => {
                              setSelectedImage(file);
                            }}
                          >
                            {image}
                          </div>
                          <div className="text-gray-500 text-xs uppercase text-center mt-1">
                            {file.fileType}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div></div>
            <div className="mt-2">
              <TextAreaInput
                label="Remark"
                placeholder={`Send a remark to ${selectedActivity.userProgram.user.firstName} they will be notified via email.`}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      )}
      {openImageFull && (
        <Modal
          isOpen={openImageFull}
          onClose={() => {
            setOpenImageFull(false);
          }}
        >
          <img src={selectedImage.fileUrl} />
        </Modal>
      )}

      <div className="cursor-pointer mb-5" onClick={() => navigate(-1)}>
        <IoIosArrowRoundBack className="w-8 h-8" />
      </div>
      <div className="mb-5">
        <TitleCards
          title={
            <>
              <div className="flex justify-between">
                <p>Activities</p>
              </div>
            </>
          }
          subtitle="Activities progress"
        >
          {!loadingActivityPerDay && (
            <div className="flex flex-row flex-wrap gap-5">
              {activityPerDay?.activityPerDay?.map((activity) => (
                <>
                  <div
                    className="flex group flex-col mb-2 cursor-pointer hover:bg-gray-100 py-3 px-5 rounded duration-150 border border-gray-200"
                    key={activity.id}
                    onClick={() => {
                      navigate(
                        `/app/me/programs/manage/view-completed/${
                          new Date(activity.date).toISOString().split("T")[0]
                        }`,
                        {
                          state: {
                            ...activity,
                            programId: location.state.programId,
                            ISODate: new Date(activity.date).toISOString(),
                          },
                        }
                      );
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium text-gray-600">
                          Day {activity.day} |{" "}
                          {new Date(activity.date).toDateString()}
                        </h3>
                        <p className="text-sm text-gray-500 mt-0">
                          {activity.completed}{" "}
                          {activity.completed > 1 ? "people" : "person"}{" "}
                          completed / {activity.void} void
                        </p>
                      </div>
                      <div className="ml-4 group-hover:opacity-100 opacity-0 -translate-x-7 group-hover:translate-x-0 duration-150">
                        <HiOutlineChevronRight />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </TitleCards>
      </div>
      <TitleCards
        title={`Completed activities for ${new Date(
          location.state.date
        ).toDateString()}`}
        subtitle={
          <>
            {submissions && (
              <div className="cursor-pointer">
                {submissions?.length}{" "}
                {submissions.length > 1 ? "people" : "person"} completed
                activity
              </div>
            )}
          </>
        }
      >
        <div>
          {submissions && submissions.length > 0 && (
            <>
              <GroupedSubmissions
                submissions={submissions}
                setSelectedActivity={setSelectedActivity}
                setSelectedImage={setSelectedImage}
              />
            </>
          )}
        </div>
      </TitleCards>
    </div>
  );
};

export default ViewCompleted;

const GroupedSubmissions = ({
  submissions,
  setSelectedActivity,
  setSelectedImage,
}) => {
  const groupSubmissionByDate = useCallback(() => {
    return submissions.reduce((acc, curr) => {
      const date = new Date(curr.createdAt).toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = {
          date: date,
          dueDate: curr.activityDueDate,
          submissions: [],
        };
      }
      acc[date].submissions.push(curr);
      return acc;
    }, []);
  }, [submissions]);

  const grouped = groupSubmissionByDate();
  const dates = Object.keys(grouped).sort((a, b) => {
    return new Date(a) - new Date(b);
  });

  const displayDate = useCallback((start, end, count) => {
    const passedDays = passedDaysFromTo(start, end);
    if (passedDays.sameDay) {
      return `Same day - (${count}) - ${dayjs(start).format("DD MMM YYYY")}`;
    }
    return `${passedDays.daysDiff} ${
      passedDays.daysDiff > 1 ? "days" : "day"
    } after - (${count}) - ${dayjs(start).format("DD MMM YYYY")}`;
  }, []);

  return dates.map((date) => (
    <details key={date} className="appearance-none">
      <summary className="appearance-none px-3 bg-gray-100 py-4 border-b-2 cursor-pointer text-gray-500">
        {displayDate(
          grouped[date].dueDate,
          date,
          grouped[date]?.submissions.length
        )}
      </summary>
      <div>
        <div className="relative overflow-x-auto mt-0">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-100 uppercase bg-blue-500 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  User
                </th>
                <th scope="col" className="px-6 py-3">
                  Submission
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {/*{groupSubmissionByDate(submissions ? submissions : []).toLocaleString()}*/}
              {grouped[date]?.submissions?.map((info, index) => (
                <tr
                  onClick={() => {
                    const imagesList = info.SubmittedActivityFiles;
                    setSelectedActivity({
                      ...info,
                      imagesList,
                    });
                    setSelectedImage(imagesList[0]);
                  }}
                  className="bg-white border-b cursor-pointer"
                  key={index}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="flex flex-col">
                      <span className="text-gray-700">
                        {info.userProgram.user.firstName}{" "}
                        {info.userProgram.user.lastName}
                      </span>
                      <span className="text-gray-400">
                        {info.userProgram.user.email}
                      </span>
                    </div>
                  </th>
                  <td className="px-6 py-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncate(linkify(info.submission)[0], 100),
                      }}
                    ></div>
                  </td>
                  <td className="px-6 py-4">
                    <span className="text-gray-400">
                      <Button
                        size="xs"
                        variant="ghost"
                        onClick={() => {
                          const imagesList = info.SubmittedActivityFiles;
                          setSelectedActivity({
                            ...info,
                            imagesList,
                          });
                          setSelectedImage(imagesList[0]);
                        }}
                      >
                        Give Remark
                      </Button>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </details>
  ));
};
