import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import "../../../../styles/date-picker.css";
import TextInput from "../../../../components/Inputs/TextInput";
import TextAreaInput from "../../../../components/Inputs/TextareaInput";
import { Button } from "../../../../components/Buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import http from "../../../../api/http";
import { toast } from "react-toastify";
import useAuth from "../../../../protector/AuthService";
import { HiOutlineDuplicate } from "react-icons/hi";
import FileInput from "../../../../components/Inputs/FIleInput.jsx";
import CreatedActivityList from "../../../../components/Activities/CreatedActivityList.jsx";

const sortActivityByDate = (activity = []) => {
  return activity.sort((a, b) => {
    return new Date(b.dueDate) - new Date(a.dueDate);
  });
};

const getActivities = async (programId) => {
  return http.get(`/program/activities/${programId}`);
};

const CreateActivites = () => {
  const auth = useAuth();
  const { state } = useLocation();
  const [selected, setSelected] = useState("");
  const [selections, setSelections] = useState([]);
  const [savedActivities, setSavedActivities] = useState([]);
  const [defaultActivity, setDefaultActivity] = useState([]);
  // const [clearAfterSave, setClearAfterSave] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isGlobalEdit, setIsGlobalEdit] = useState(false);
  const location = useLocation();
  const [videoFile, setVideoFile] = useState(null);
  const [program, setProgram] = useState({
    startDate: "",
    endDate: "",
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      title: "",
      description: "",
      instructions: "",
      dueDate: "",
      submissionRequired: true,
      attachmentRequired: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      instructions: Yup.string().required("Required"),
      submissionRequired: Yup.boolean().required("Required"),
      attachmentRequired: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      const formatted = format(new Date(selected), "yyyy-MM-dd");

      const payload = {
        ...values,
        dueDate: new Date(formatted).toISOString(),
        submissionRequired: values.submissionRequired ? 1 : 0,
        attachmentRequired: values.attachmentRequired ? 1 : 0,
        video: videoFile,
      };
      let req = "";

      const form = new FormData();

      for (const key in payload) {
        form.append(key, payload[key]);
      }

      try {
        req = await http.post(`/program/${state.program.id}/activity`, form, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        const { data } = req;
        const newSavedActivities = [...savedActivities];
        const index = newSavedActivities.findIndex(
          (activity) => activity.id === data.id
        );
        if (index !== -1) {
          newSavedActivities[index] = data;
        } else {
          newSavedActivities.push(data);
        }
        setSavedActivities(newSavedActivities);

        let tom = new Date(new Date(selected));
        tom.setDate(tom.getDate() + 1);

        if (tom > new Date(state.program.endDate)) {
          tom = new Date(state.program.endDate);
        } else {
          setSelected(tom);
          formik.resetForm();
          formik.setFieldValue("dueDate", "");
          formik.setFieldValue("submissionRequired", true);
        }

        setIsEditing(false);
        toast.success("Activity saved");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        toast.error("Failed to save activity");
      }
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const datesInSavedActivities = savedActivities.map(
      (activity) => new Date(activity.dueDate)
    );
    setSelections(datesInSavedActivities);
    const isDone = checkIfAllDaysAreFilledWithActivity(
      savedActivities,
      program.startDate,
      program.endDate
    );
    if (isDone && !isGlobalEdit && savedActivities.length > 0) {
      navigate("/app/me/programs/view", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedActivities]);

  useEffect(() => {
    const isActivityFilled = savedActivities.find(
      (activity) =>
        new Date(activity.dueDate).toISOString() ===
        new Date(format(new Date(selected), "yyyy-MM-dd")).toISOString()
    );

    if (isActivityFilled) {
      formik.setFieldValue("id", isActivityFilled.id);
      formik.setFieldValue("title", isActivityFilled.title);
      formik.setFieldValue("description", isActivityFilled.description);
      formik.setFieldValue("instructions", isActivityFilled.instructions);
      formik.setFieldValue(
        "submissionRequired",
        isActivityFilled.submissionRequired
      );
      formik.setFieldValue("dueDate", new Date(isActivityFilled.dueDate));
      setIsEditing(true);
    } else {
      formik.resetForm();
      formik.setFieldValue("dueDate", selected);
      formik.setFieldValue("submissionRequired", true);
      setIsEditing(false);
    }
  }, [selected, savedActivities]);

  useEffect(() => {
    if (location.state) {
      const program = {
        startDate: format(new Date(state?.program?.startDate), "yyyy/MM/dd"),
        endDate: format(new Date(state?.program?.endDate), "yyyy/MM/dd"),
      };
      setProgram(program);
      const aSelected = format(new Date(state.program.startDate), "yyyy/MM/dd");
      setSelected(aSelected);

      const activities =
        defaultActivity.length > 0
          ? defaultActivity
          : location?.state?.activities;
      // const activities = location?.state?.activities;
      if (Array.isArray(activities)) {
        setIsGlobalEdit(true);
        setSavedActivities(activities);
        const isActivityFilled = activities.find(
          (activity) =>
            new Date(activity.dueDate).toISOString() ===
            new Date(format(new Date(aSelected), "yyyy-MM-dd")).toISOString()
        );

        if (isActivityFilled) {
          formik.setFieldValue("id", isActivityFilled.id);
          formik.setFieldValue("title", isActivityFilled.title);
          formik.setFieldValue("description", isActivityFilled.description);
          formik.setFieldValue("instructions", isActivityFilled.instructions);
          formik.setFieldError(
            "submissionRequired",
            isActivityFilled.submissionRequired
          );
          formik.setFieldValue("dueDate", new Date(isActivityFilled.dueDate));
          setIsEditing(true);
        } else {
          formik.resetForm();
          formik.setFieldValue("dueDate", aSelected);
          setIsEditing(false);
        }
      }
    } else {
      navigate("/app/me/programs/view", {
        replace: true,
      });
    }
  }, [location.state, defaultActivity]);

  useEffect(() => {
    if (!state || !state.program) {
      navigate("/app/me/programs/view", {
        replace: true,
      });
      return;
    }

    if (state.program.id) {
      const getCreatedActivities = async () => {
        const { data } = await getActivities(state.program.id);
        setDefaultActivity(data);
      };
      getCreatedActivities();
    }
  }, [state.program.id]);

  const duplicateFromLastActivity = () => {
    const lastActivity = sortActivityByDate(savedActivities)[0];
    formik.setFieldValue("title", lastActivity.title);
    formik.setFieldValue("description", lastActivity.description);
    formik.setFieldValue("instructions", lastActivity.instructions);
    formik.setFieldValue("submissionRequired", lastActivity.submissionRequired);
  };

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
      {selected && (
        <div>
          <DayPicker
            mode="single"
            defaultMonth={new Date(program.startDate)}
            fromMonth={new Date(program.startDate)}
            toMonth={new Date(program.endDate)}
            disabled={disabledDays(program.startDate, program.endDate)}
            selected={selections}
            onDayClick={(day) => {
              setSelected(day);
            }}
            showOutsideDays
          />
          <CreatedActivityList activity={sortActivityByDate(savedActivities)} />
        </div>
      )}

      <div>
        <div className="bg-gray-100 max-w-xl">
          {selected && (
            <h2 className="text-2xl font-bold">
              {isEditing ? "Edit" : "Create"} Activity for{" "}
              <span className="text-blue-500">
                {format(
                  new Date(new Date(selected).toISOString()),
                  "dd MMMM yyyy"
                )}
              </span>
            </h2>
          )}

          <p className="text-gray-400 mt-1">
            Create activities for the program date.
          </p>
          <div className="mt-10">
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-5">
                <div className="flex items-center justify-end">
                  <Button
                    size="xs"
                    onClick={duplicateFromLastActivity}
                    variant="text"
                    type="button"
                  >
                    <div className="flex items-center">
                      <div className="mr-2">
                        <HiOutlineDuplicate />
                      </div>
                      <div>Duplicate from previous day</div>
                    </div>
                  </Button>
                </div>
                <div>
                  <TextInput
                    name="title"
                    label="Activity Title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    error={formik.touched.title && formik.errors.title}
                  />
                </div>
                <div>
                  <TextAreaInput
                    name="description"
                    label="Activity Description"
                    rows={5}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    error={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </div>
                <div>
                  <TextAreaInput
                    name="instructions"
                    label="Activity Instruction"
                    onChange={formik.handleChange}
                    value={formik.values.instructions}
                    error={
                      formik.touched.instructions && formik.errors.instructions
                    }
                  />
                </div>
                {/* TODO: 30 mins limit */}
                <div>
                  <FileInput
                    name="video"
                    label="Add a video (Optional)"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.size > 650000000) {
                        toast.error("File size is too big");
                        return;
                      }
                      if (file.type !== "video/mp4") {
                        toast.error("File type is not supported");
                        return;
                      }
                      setVideoFile(file);
                    }}
                  />
                </div>
                <div className="grid grid-cols-2 gap-10">
                  <div>
                    <label className="text-sm block">
                      <input
                        name="submissionRequired"
                        type="checkbox"
                        className={`mr-2 form-checkbox ring-blue-500 ring-offset-blue-500 rounded ${formik.values.submissionRequired}`}
                        onChange={formik.handleChange}
                        value={formik.values.submissionRequired}
                        checked={formik.values.submissionRequired}
                      />
                      <span className="text-gray-400">
                        Does this activity requires a submission from members.
                      </span>
                    </label>
                  </div>
                  <div>
                    <label className="text-sm block">
                      <input
                        name="attachmentRequired"
                        type="checkbox"
                        className={`mr-2 form-checkbox ring-blue-500 ring-offset-blue-500 rounded ${formik.values.attachmentRequired}`}
                        onChange={formik.handleChange}
                        value={formik.values.attachmentRequired}
                        checked={formik.values.attachmentRequired}
                      />
                      <span className="text-gray-400">
                        Does this activity require an attachment from members
                        (JPG, PNG, PDF, DOCX, CSV, XLSX).
                      </span>
                    </label>
                  </div>
                </div>
                {/* <div className=" justify-end hidden">
                  <label>
                    <input
                      type="checkbox"
                      checked={clearAfterSave}
                      onChange={() => setClearAfterSave(!clearAfterSave)}
                    />
                    <span className="ml-2 text-gray-400">Clear after save</span>
                  </label>
                </div> */}
                <div className="flex justify-end">
                  {isGlobalEdit && (
                    <Button
                      type="button"
                      onClick={() => {
                        navigate("/app/me/programs/view", {
                          replace: true,
                        });
                      }}
                    >
                      Done Editing
                    </Button>
                  )}
                  <Button type="submit" loading={formik.isSubmitting}>
                    {isEditing ? "Update" : "Create"} Activity
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateActivites;

const disabledDays = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const fromBeforeStartDate = new Date(
    start.getFullYear(),
    start.getMonth(),
    start.getDate() !== 1 ? 1 : start.getDate() - 1
  );
  const toBeforeStartDate = new Date(
    start.getFullYear(),
    start.getMonth(),
    start.getDate() - 1
  );

  const fromAfterEndDate = new Date(
    end.getFullYear(),
    end.getMonth(),
    end.getDate() + 1
  );
  const toAfterEndDate = new Date(
    end.getFullYear(),
    end.getMonth(),
    end.getDate() !== 31 ? 31 : end.getDate() + 1
  );
  return [
    { from: fromBeforeStartDate, to: toBeforeStartDate },
    {
      from: fromAfterEndDate,
      to: toAfterEndDate,
    },
  ];
};

const checkIfAllDaysAreFilledWithActivity = (
  savedActivities,
  startDate,
  endDate
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const totalDuration = end.getDate() - start.getDate();
  const totalActivities = savedActivities.length;
  return totalActivities === totalDuration + 1;
};
