import { format } from "date-fns";
import { linkify } from "../../utils/linkify";
import Card from "../Cards/Card";
import { AiOutlineDown } from "react-icons/ai";

const CreatedActivityList = ({ activity }) => {
  return (
    <div>
      <div className="mt-10">
        <Card className="p-0">
          <h4 className="font-semibold text-lg text-gray-500 px-4 pt-4">
            Activity List
          </h4>
          <div className="mt-2">
            {activity.map((activity) => (
              <div key={activity.id}>
                <details className="appearance-none">
                  <summary className="cursor-pointer appearance-none border-b hover:bg-gray-200 px-4 py-2 flex items-center justify-between">
                    <div>
                      {" "}
                      <p className="text-xs text-gray-500">
                        {format(new Date(activity.dueDate), "dd MMMM yyyy")}
                      </p>
                      <p className="font-medium overflow-ellipsis">
                        {activity.title}
                      </p>
                    </div>
                    <div className="open:rotate-180 transform duration-300">
                      <AiOutlineDown className="text-gray-400" />
                    </div>
                  </summary>
                  <div className="border-b-2 border-gray-200 pb-2 px-4 max-h-[300px] overflow-y-scroll">
                    <div className="text-gray-400 mt-2 text-sm">
                      Description:{" "}
                      <div
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: linkify(activity.description),
                        }}
                      ></div>
                    </div>
                    <div className="text-gray-400 mt-2 text-sm">
                      Instructions:{" "}
                      <div>
                        <div
                          style={{ whiteSpace: "pre-wrap" }}
                          dangerouslySetInnerHTML={{
                            __html: linkify(activity.instructions),
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </details>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CreatedActivityList;
