import ReactStars from "react-stars";
import { Button } from "../Buttons";
import Modal from "../Modal/Modal";
import http from "../../api/http";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useAuth from "../../protector/AuthService";
import { passedDays } from "../../utils/dueTimes";

const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [step, setStep] = useState(0);
  const [givingFeedback, setGivingFeedback] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuth();

  const giveFeedback = async () => {
    setGivingFeedback(true);
    try {
      await http.post("/feedback", {
        rating,
        question:
          step === 0
            ? "RATE_YOUR_EXPERIENCE_ON_DAILYDO"
            : "HOW_LIKELY_WOULD_YOU_RECOMMEND_DAILYDO_TO_A_COMMUNITY_PROGRAM_MANAGER",
      });
      toast.info("Thank you!");
      setRating(0);
      if (step === 0) {
        setStep(step + 1);
      }
      if (step === 1) {
        setIsOpen(false);
      }
      setGivingFeedback(false);
    } catch (error) {
      toast.info("Unable to give feedback, we are working on it.");
      setGivingFeedback(false);
    }
  };

  useEffect(() => {
    const checkFeedback = async () => {
      const userRegDate = new Date(user?.createdAt || Date.now());

      const days = passedDays(userRegDate);

      if (days < 7) return;

      try {
        const { data } = await http.get("/feedback/check");
        let step = 0;
        if (data.RATE_YOUR_EXPERIENCE_ON_DAILYDO) {
          step = 1;
        }
        if (
          data.HOW_LIKELY_WOULD_YOU_RECOMMEND_DAILYDO_TO_A_COMMUNITY_PROGRAM_MANAGER
        ) {
          step = 2;
        }
        if (step === 0 || step === 1) {
          setIsOpen(true);
          setStep(step);
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkFeedback();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title={""}
      onClose={() => {
        givingFeedback ? null : setIsOpen(false);
      }}
      actions={
        <>
          <Button
            variant="solid"
            size="md"
            fullWidth
            onClick={giveFeedback}
            loading={givingFeedback}
          >
            Submit
          </Button>
        </>
      }
    >
      <div className="text-center">
        <h2 className="font-bold">Thank you for using dailydo!</h2>
        <p className="text-gray-500">
          We&apos;d like to get your feedback to help us improve.
        </p>
        {step === 0 && (
          <div className="py-4">
            <h2 className="md:text-3xl text-2xl">
              Rate your experience on dailydo
            </h2>
            <div className="flex mt-5 items-center justify-center">
              <ReactStars
                count={5}
                size={48}
                value={rating}
                onChange={setRating}
                color2={"#3b83f6"}
              />
            </div>
          </div>
        )}
        {step === 1 && (
          <div className="py-4">
            <h2 className="md:text-3xl text-2xl">
              How likely are you to recommend dailydo to a community/program
              manager
            </h2>
            <div className="mt-10">
              <div className="flex items-center justify-between text-xs text-gray-500">
                <span>I won&apos;t recommend</span>
                <span>I&apos;ll definitely recommend</span>
              </div>
              <div className="grid grid-cols-5 md:gap-5 gap-3 mt-2">
                <span
                  className={`${
                    rating === 1 ? "bg-amber-500" : "bg-blue-500 opacity-20"
                  } md:w-16 md:h-16 w-10 h-10 cursor-pointer`}
                  onClick={() => setRating(1)}
                ></span>
                <span
                  className={`${
                    rating === 2 ? "bg-amber-500" : "bg-blue-500 opacity-40"
                  } md:w-16 md:h-16 w-10 h-10 cursor-pointer`}
                  onClick={() => setRating(2)}
                ></span>
                <span
                  className={`${
                    rating === 3 ? "bg-amber-500" : "bg-blue-500 opacity-60"
                  } md:w-16 md:h-16 w-10 h-10 cursor-pointer`}
                  onClick={() => setRating(3)}
                ></span>
                <span
                  className={`${
                    rating === 4 ? "bg-amber-500" : "bg-blue-500 opacity-80"
                  } md:w-16 md:h-16 w-10 h-10 cursor-pointer`}
                  onClick={() => setRating(4)}
                ></span>
                <span
                  className={`${
                    rating === 5 ? "bg-amber-500" : "bg-blue-500"
                  } md:w-16 md:h-16 w-10 h-10 cursor-pointer`}
                  onClick={() => setRating(5)}
                ></span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Feedback;
