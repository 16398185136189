import { cva } from "class-variance-authority";

const switchCVA = cva(
  "bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-200 rounded-full peer after:content-[''] after:absolute  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all peer-checked:bg-blue-500",
  {
    variants: {
      size: {
        sm: [
          "w-9",
          "h-5",
          "after:top-[2px]",
          "after:left-[2px]",
          "after:h-4",
          "after:w-4",
        ],
        md: [
          "w-11",
          "h-6",
          "after:top-[2px]",
          "after:left-[2px]",
          "after:h-5",
          "after:w-5",
        ],
        lg: [
          "w-14 h-7",
          "after:top-0.5",
          "after:left-[4px]",
          "after:h-6",
          "after:w-6",
        ],
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);

const Switch = ({ name, label, value, onChange }) => {
  return (
    <>
      <label className="relative inline-flex items-center cursor-pointer text-gray-400">
        <div className="flex-shrink-0">
          <input
            name={name}
            type="checkbox"
            value={value}
            checked={value}
            onChange={onChange}
            className="sr-only peer"
          />
          <div className={switchCVA({})}></div>
        </div>
        <span className="ml-3 font-medium text-gray-500 text-base">
          {label}
        </span>
      </label>
    </>
  );
};

export default Switch;
