import * as Sentry from "@sentry/react";
import Navigation from "./navigation/router";
import { AuthProvider } from "./protector/AuthService";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const isDEV = import.meta.env.DEV;

function initSentry() {
  Sentry.init({
    dsn: "https://f11ac81ea9d25e3bf77e2b2a1526728f@o4504244739899392.ingest.sentry.io/4506377598271488",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https://app.dailydoai.com/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: isDEV ? 1 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (!isDEV) {
  initSentry();
}

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <div>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Navigation />
          </AuthProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </div>
    </>
  );
}

export default App;
