import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
// import "videojs-resolution-switcher-vjs7"
import "videojs-resolution-switcher-vjs7/videojs-resolution-switcher.css";
// import '../styles/video-js.css'
import "videojs-mobile-ui/dist/videojs-mobile-ui.css";
import "videojs-mobile-ui";
import { loadVideoPlugins } from "./loadingVideoPLugin";

export const VideoJS = ({ options, onReady }) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  // const { options, onReady } = props;

  const updatedOptions = {
    ...options,
    plugins: {
      videoJsResolutionSwitcher: {
        default: "high",
        dynamicLabel: true,
      },
    },
  };

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      window.videojs = videojs;
      loadVideoPlugins(window, videojs);
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        updatedOptions,
        () => {
          videojs.log("player is ready");
          window.videojs = videojs;
          onReady && onReady(player);
        }
      ));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.autoplay(updatedOptions.autoplay);
      player.src(updatedOptions.sources);
      player.updateSrc(updatedOptions.sources);
      player.mobileUi();
    }
  }, [updatedOptions, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
