import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import http from "../../api/http";
import DueActivities from "../../components/Activities/DueActivities";
import UpcomingActivities from "../../components/Activities/UpcomingActivities";
import ActivitySubmission from "../../components/ActivitySubmission.jsx";
import { Button } from "../../components/Buttons";
import Card from "../../components/Cards/Card";
import TitleCards from "../../components/Cards/TitleCards";
import Feedback from "../../components/Feedback/Feedback.jsx";
import UpcomingActivityPreview from "../../components/UpcomingActivityPreview.jsx";
import useAuth from "../../protector/AuthService";
import { truncate } from "../../utils/truncate";

const hasPreviousSubmission = (activityId, submittedActivites) => {
  return submittedActivites.find(
    (submission) => submission.activityId === activityId
  );
};

const AppHome = () => {
  const {
    isLoading: dueActivityLoading,
    data: dueActivities,
    refetch: refetchDueActivities,
  } = useQuery({
    queryKey: ["dueActivities"],
    queryFn: async () =>
      (await http.get(`/program/activities/today`)).data.data,
  });

  const { isLoading: upcomingActivityLoading, data: upcomingActivities } =
    useQuery({
      queryKey: ["upcomingActivities"],
      queryFn: async () =>
        (await http.get(`/program/activities/upcoming`)).data.data,
    });

  const { data: myProgram } = useQuery({
    queryKey: ["myProgram"],
    queryFn: async () => (await http.get(`/program/all/overview`)).data,
  });

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpcomingModalOpen, setIsUpcomingModalOpen] = useState(false);

  const auth = useAuth();
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedUpcomingActivity, setSelectedUpcomingActivity] =
    useState(null);

  const countDueToday = useCallback(() => {
    let count = 0;
    dueActivities?.userProgram?.forEach((program) => {
      program?.program?.activity?.forEach((activity) => {
        if (
          new Date(activity.dueDate).toISOString().slice(0, 10) ===
          new Date().toISOString().slice(0, 10)
        ) {
          count++;
        }
      });
    });
    return count;
  }, [dueActivities]);

  const isActivities = useCallback((activities = []) => {
    let isActivities = false;

    if (activities) {
      for (let i = 0; i < activities.length; i++) {
        if (activities[i].program.activity.length > 0) {
          isActivities = true;
          break;
        }
      }
    }

    return !isActivities;
  }, []);

  return (
    <div>
      <Feedback />

      {selectedActivity && (
        <ActivitySubmission
          activity={selectedActivity}
          setActivity={setSelectedActivity}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetchDueActivities={refetchDueActivities}
        />
      )}

      {
        selectedUpcomingActivity && (
          <UpcomingActivityPreview
            activity={selectedUpcomingActivity}
            setActivity={setSelectedUpcomingActivity}
            isModalOpen={isUpcomingModalOpen}
            setIsModalOpen={setIsUpcomingModalOpen}
          />
        )
      }

      <div className="md:flex gap-10">
        <div className="md:w-8/12 w-full">
          <Card>
            <div className="md:flex items-center justify-between">
              <div>
                <h2 className="font-medium md:text-2xl text-xl">
                  Welcome back, {auth?.user?.firstName}.{" "}
                </h2>
                <p className="text-gray-400 mt-1 md:text-base text-sm">
                  You are part of a community of leading experts in the design
                </p>
              </div>
              <div className="mt-4 md:mt-0">
                <Link to="programs/create">
                  <Button variant="ghost" size="sm">
                    Create program &rarr;{" "}
                  </Button>
                </Link>
              </div>
            </div>
          </Card>

          <TitleCards
            className="mt-10"
            noData={isActivities(dueActivities?.userProgram)}
            noDataMessage="No due activities for today"
            loading={dueActivityLoading}
            title={
              <div className="flex items-center md:justify-start justify-between">
                <h2 className="font-medium lg:text-xl text-lg mr-2">
                  {" "}
                  Due Activities
                </h2>
                <span className="text-xs border-2 border-blue-500 text-blue-500 md:px-4 px-2 py-1 rounded">
                  {countDueToday()} due activities today
                </span>
              </div>
            }
            subtitle="Keep track of your activities and progress"
          >
            <div className="mt-10 grid md:grid-cols-3 grid-cols-1 gap-5">
              {dueActivities?.userProgram?.map((program) => {
                return program?.program?.activity?.map((activity) => {
                  return (
                    <DueActivities
                      key={activity.id}
                      dueDate={activity.dueDate}
                      programTitle={program.program.title}
                      activityTitle={activity.title}
                      activityInstruction={activity.instructions}
                      image={program.program.image}
                      totalSubmissions={
                        dueActivities.totalSubmissionPerActivity.find(
                          (a) => a.activityId === activity.id
                        )?._count.activityId || 0
                      }
                      action={() => {
                        const submission = hasPreviousSubmission(
                          activity.id,
                          program.SubmittedActivity
                        );

                        const createdActivity = {
                          ...activity,
                          activityId: activity.id,
                          userProgramId: program.id,
                        };

                        if (submission) {
                          createdActivity.submission = submission.submission;
                          createdActivity.images =
                            submission.SubmittedActivityFiles;
                          createdActivity.files = [];
                        }

                        setSelectedActivity(createdActivity);
                        setIsModalOpen(true);
                      }}
                      buttonText={
                        hasPreviousSubmission(
                          activity.id,
                          program.SubmittedActivity
                        )
                          ? "Update"
                          : "Submit"
                      }
                    />
                  );
                });
              })}
            </div>{" "}
          </TitleCards>
          <TitleCards
            className="mt-10"
            title="Upcoming Activities"
            subtitle="Keep track of your activities and progress"
            noData={isActivities(upcomingActivities)}
            noDataMessage="No upcoming activities"
            loading={upcomingActivityLoading}
          >
            <div className="mt-10 grid md:grid-cols-3 grid-cols-1 gap-5">
              {upcomingActivities?.map((program) => {
                return program?.program?.activity?.map((activity) => {
                  return (
                    <UpcomingActivities
                      key={activity.id}
                      dueDate={activity.dueDate}
                      image={program.program.image}
                      programTitle={program.program.title}
                      activityTitle={activity.title}
                      activityInstruction={activity.instructions}
                      isVisible={program?.program?.showFutureActivity}
                      action={() => {
                        setSelectedUpcomingActivity(activity);
                        setIsUpcomingModalOpen(true);
                      }}
                    />
                  );
                });
              })}
            </div>{" "}
          </TitleCards>
        </div>
        <div className="md:w-4/12 mt-10 md:mt-0">
          {myProgram && Array.isArray(myProgram) && myProgram.length > 0 && (
            <TitleCards
              title={
                <>
                  <div className="flex items-center justify-between">
                    <div>My Programs</div>
                    <div>
                      <Link to={"/app/me/programs/view"}>
                        <Button variant="outline" size="xs">
                          View all &rarr;
                        </Button>
                      </Link>
                    </div>
                  </div>
                </>
              }
              subtitle="View your program overview"
            >
              <div>
                {myProgram?.map((program) => {
                  return (
                    <div
                      key={program.id}
                      className="mt-5 p-4 hover:bg-gray-100 cursor-pointer rounded"
                      onClick={() => {
                        navigate("/app/me/programs/manage/content", {
                          state: { program },
                        });
                      }}
                    >
                      <h3 className="font-medium text-lg">{program.title}</h3>
                      <p className="text-gray-400 mt-1">
                        {truncate(program.description, 100)}
                      </p>
                      <div className="mt-4 flex flex-wrap gap-2">
                        <span className="text-sm px-3 py-1 bg-gray-500 rounded-full text-gray-50">
                          {program.duration} days program
                        </span>
                        <span className="text-sm px-3 py-1 bg-gray-500 rounded-full text-gray-50">
                          {program._count.UserProgram}{" "}
                          {program._count.UserProgram > 1 ? "people" : "person"}{" "}
                          enrolled
                        </span>
                        <span className="text-sm px-3 py-1 bg-gray-500 rounded-full text-gray-50">
                          {program._count.activity}{" "}
                          {program._count.activity > 1
                            ? "activities"
                            : "activity"}{" "}
                          created
                        </span>
                        <span className="text-sm px-3 py-1 bg-gray-500 rounded-full text-gray-50">
                          {program.submission} total submissions
                        </span>
                        {program._count.JoinProgramRequest > 0 && (
                          <span className="text-sm px-3 py-1 bg-gray-500 rounded-full text-gray-50">
                            {program._count.JoinProgramRequest} pending request
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </TitleCards>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppHome;
