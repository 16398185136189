import { useEffect, useId, useRef, useState } from "react";
import { debounce } from "lodash";

export const TextAreaFormatted = ({ label, error, ...props }) => {
  const id = useId();

  const [content, setContent] = useState(props.value || "");
  const editorRef = useRef();

  useEffect(() => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(editorRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }, [content]);

  useEffect(() => {
    if (props.value) {
      setContent(props.value);
      editorRef.current.innerHTML = props.value;
    }
  }, [editorRef.current]);

  const handleInputChange = (e) => {
    const userInput = e.target.innerHTML;

    // Apply bold style to text between *
    const boldRegex = /\*(.*?)\*/g;
    const contentWithBold = userInput.replace(boldRegex, " <b>$1</b> ");

    // Apply italic style to text between _
    const italicRegex = /_(.*?)_/g;
    const contentWithItalic = contentWithBold.replace(
      italicRegex,
      " <em>$1</em> "
    );

    const strikeRegex = /~(.*?)~/g;
    const contentWithStrike = contentWithItalic.replace(
      strikeRegex,
      " <del>$1</del> "
    );

    setContent(contentWithStrike);
    props.onChange(contentWithStrike);
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const plainText = (e.clipboardData || window.clipboardData).getData(
      "text/plain"
    );

    document.execCommand("insertText", false, plainText);
  };

  return (
    <div className="">
      <label
        className="block text-gray-500 font-medium cursor-pointer"
        htmlFor={id}
      >
        {label}
      </label>
      <div
        ref={editorRef}
        onPaste={handlePaste}
        contentEditable
        className="border-2 border-gray-400 mt-1 placeholder:text-gray-400 text-gray-500 focus-within:border-gray-700 duration-150 px-3 py-3 rounded w-full outline-none disabled:cursor-not-allowed min-h-[150px]"
        onInput={debounce(handleInputChange, 1000)}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      {error && (
        <div>
          <p className="text-red-400 text-sm mt-1">{error}</p>
        </div>
      )}
    </div>
  );
};

export default TextAreaFormatted;
