import { useId } from "react";

export const TextInput = ({ type = "text", placeholder, label, error, ...props }) => {
  const id = useId();

  return (
    <div className="">
      <label
        className="block text-gray-500 font-medium cursor-pointer"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="flex items-center justify-start border-2 border-gray-400 rounded mt-1 placeholder:text-gray-400 text-gray-500 focus-within:border-gray-700 duration-150">
        <div></div>
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          className="px-3 py-3 rounded w-full outline-none disabled:cursor-not-allowed"
          {...props}
        />
        <div></div>
      </div>
      {error && (
        <div>
          <p className="text-red-400 text-sm mt-1">{error}</p>
        </div>
      )}
    </div>
  );
};

export default TextInput;
