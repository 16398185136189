export const linkify = (text) => {
  const regex = /(\b(https?|www)\S+\b|\b\w+\.\w+\S*\b)/g;
  const links = text.match(regex);

  let newText = text;

  newText = newText.replace(/\n/gi, "<br />");
  newText = parseText(text);

  return [newText, links];
};

function parseText(text) {
  // Parse links and replace with anchor tags
  text = text.replace(/(?:http|https):\/\/[^\s)]+/g, function (url) {
    return (
      '<a class="text-blue-500 underline" href="' +
      prependHttp(url) +
      '" target="_blank">' +
      url +
      "</a>"
    );
  });

  // Parse text between ** and replace with bold
  text = text.replace(/\*(.*?)\*/g, "<strong>$1</strong>");

  // Parse text between __ and replace with italics
  text = text.replace(/_(.*?)_/g, "<em>$1</em>");

  return text;
}

const prependHttp = (url) => {
  if (url.startsWith("www")) {
    return `https://${url}`;
  } else if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return `https://${url}`;
  }
};
