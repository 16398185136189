import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import useAuth from "../../protector/AuthService";

const KEY = "notification-open-status-2";

const Notification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    const status = localStorage.getItem(KEY);

    if (!status) {
      localStorage.setItem(KEY, true);
      setTimeout(() => {
        setIsOpen(true);
      }, 3000);
      return;
    }

    setIsOpen(false);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={"Exciting New Feature For You"}
    >
      <div className="text-gray-400">
        <p>Hey {user?.displayName},</p>
        <div className="m-0 leading-6 mt-2">
          Guess what? dailydo AI just got a whole lot cooler, and I
          couldn&apos;t wait to share it with you!
          <br />
          <br />
          Now, while submitting your activities, you can:
          <br />
          <ul className="space-y-1 list-disc list-outside">
            <li className="list-item ml-7">
              Upload PNG, XLSX, DOC, and PDF files. How cool is that?
            </li>
            <li className="list-item ml-7">
              Add some flair to your text with *<b>bold</b>*, _<i>italics</i>_,
              or a bit of ~<del>strikethrough</del>~ action.
            </li>
          </ul>
          <br />
          But wait, there&apos;s more for program/community managers(yes, that
          includes you!):
          <ul className="space-y-1 list-disc list-outside">
            <li className="list-item ml-7">
              Spice up your activities by throwing in video files.
            </li>
            <li className="list-item ml-7">
              Grab members of your program and those in waiting list info in a
              snap with easy-peasy CSV downloads.
            </li>
            <li className="list-item ml-7">
              Keep an eye on submissions - whether members are fashionably early
              or fashionably late.
            </li>
            <li className="list-item ml-7">
              Get picky and choose the submissions that make your heart sing.
            </li>
          </ul>
          <br />
          We&apos;re all about making your dailydo experience a breeze, and
          we&apos;re crossing our fingers these upgrades will add some extra
          sunshine to your day.
          <br />
          <br />
        </div>

        <p>
          Dive in, give it a spin, and I&apos;d love to hear what you think!
        </p>
      </div>
    </Modal>
  );
};

export default Notification;
