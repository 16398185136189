import { toast } from "react-toastify";
import Button from "../Buttons/Button";
import { IoIosShareAlt } from "react-icons/io";
import { truncate } from "../../utils/truncate";
import dailyDO from "../../assets/dailydo.ai.png";

const EnrolledProgram = ({ program, status, showAction = true, onClick }) => {
  const shareLink = async () => {
    const link = `${import.meta.env.VITE_FRONTEND_URL}/join/${program.link}`;

    await navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard");
    setTimeout(() => {
      window.open(link, "_blank");
    }, 1000);
  };

  return (
    <div>
      <div>
        <div>
          <div className="relative shadow">
            {program.status === "PUBLISHED" && (
              <button
                onClick={shareLink}
                className="border-none absolute top-3 right-3 bg-blue-500 text-white rounded-full p-3"
              >
                <IoIosShareAlt />
              </button>
            )}
            {status && (
              <span className="border-none absolute text-xs top-3 left-3 bg-red-500 text-white rounded-full p-2 px-5">
                {status === "UNENROLLED_PROGRAM_DELETED"
                  ? "Program Deleted"
                  : status === "UNENROLLED_PROGRAM_DEACTIVATED"
                  ? "Program Deactivated"
                  : status === "UNENROLLED_USER_UNENROLLED"
                  ? "You Unenrolled"
                  : status === "UNENROLLED_USER_REMOVED"
                  ? "Removed from program"
                  : "Program Ended"}
              </span>
            )}

            <div className="h-[287px] rounded-sm overflow-hidden">
              <img
                src={program.image || dailyDO}
                className="rounded-md h-full w-full object-cover"
              />
            </div>
          </div>
          <div>
            <h3 className="font-medium text-xl mt-3">
              {truncate(program?.title, 20)}
            </h3>
            <p className="text-gray-400 mt-1 text-sm">
              {truncate(program?.description, 100)}
            </p>
            <div className="mt-10 text-sm text-gray-500">
              Program by {program?.creator?.displayName}
            </div>
            {showAction && (
              <div className="mt-5">
                <Button variant="outline" size="sm" fullWidth onClick={onClick}>
                  View
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrolledProgram;
