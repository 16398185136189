
import { generateSources } from "../utils/generateSources";
import { linkify } from "../utils/linkify";
import Drawer from "./Drawer/Drawer";
import VideoJS from "./VideoJS";

const UpcomingActivityPreview = ({
  activity,
  setActivity,
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <div>
      <Drawer
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setActivity(null);
        }}
        title={`View ${activity.title}`}
        actions={<></>}
      >
        <div className="">
          <h3 className="font-medium text-lg">
            {activity?.title}
          </h3>
          <div className="text-gray-400 mt-4">
            <span className="uppercase text-xs block text-gray-600">
              description
            </span>
            <div
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: linkify(activity?.description)[0],
              }}
            ></div>
          </div>
          <div className="text-gray-400 mt-4">
            <span className="uppercase text-xs block text-gray-600">
              Instructions
            </span>
            <div
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: linkify(activity?.instructions)[0],
              }}
            ></div>
          </div>
          <div className="my-5">
            {activity.ActivityVideo &&
              activity.ActivityVideo.length > 0 && (
                <>
                  <VideoJS
                    options={{
                      autoplay: false,
                      controls: true,
                      responsive: true,
                      fluid: true,
                      sources: [
                        generateSources(
                          activity.ActivityVideo[0]
                        ),
                      ],
                    }}
                  />
                </>
              )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default UpcomingActivityPreview;
