import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

const button = cva(
  "items-center justify-center text-center rounded-md font-semibold cursor-pointer uppercase text-sm border-2 duration-150 disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        solid: [
          "bg-blue-500",
          "text-white",
          "hover:bg-blue-600",
          "disabled:bg-blue-100",
        ],
        outline: [
          "border-blue-500",
          "text-blue-500",
          "hover:bg-blue-500",
          "hover:text-white",
          "disabled:bg-blue-200",
          "disabled:text-white",
          "disabled:border-blue-100",
        ],
        ghost: [
          "bg-blue-100",
          "text-blue-400",
          "hover:bg-blue-100",
          "disabled:bg-blue-100",
          "border-transparent",
        ],
        text: [
          "bg-transparent",
          "text-blue-500",
          "hover:bg-blue-50",
          "disabled:bg-blue-100",
          "border-transparent",
        ],
        danger: [
          "bg-red-500",
          "text-white",
          "hover:bg-red-600",
          "disabled:bg-red-100",
        ],
        dangerGhost: [
          "bg-red-100",
          "text-red-400",
          "hover:bg-red-100",
          "disabled:bg-red-100",
          "border-transparent",
        ],
        dangerText: [
          "bg-transparent",
          "text-red-500",
          "hover:bg-red-50",
          "disabled:bg-red-100",
          "border-transparent",
        ],
      },
      size: {
        xs: ["px-4 py-2", "text-xs"],
        sm: ["px-4 py-2", "text-sm"],
        md: ["px-6 py-3", "text-base"],
        lg: ["px-8 py-4", "text-lg"],
      },
      fullWidth: {
        true: ["w-full"],
      },
    },
    defaultVariants: {
      variant: "solid",
      size: "md",
    },
  }
);

/**
 * @typedef PrimaryButtonProps
 * @property {"solid" | "outline" | "ghost" | "text" | "danger"} variant
 * @property {"xs" | "sm" | "md" | "lg"} size
 * @property {boolean} fullWidth
 * @property {boolean} loading
 * @property {React.ReactNode} children
 */

/**
 * @param {PrimaryButtonProps|React.ButtonHTMLAttributes<HTMLButtonElement> } props
 */
export const Button = ({
  variant,
  size,
  fullWidth,
  children,
  loading,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        button({
          fullWidth,
          variant,
          size,
        })
      )}
      {...props}
      disabled={loading || props.disabled}
    >
      {loading ? <LoadingSvg /> : children}
    </button>
  );
};

export const LoadingSvg = () => (
  <div className="w-full h-full flex items-center justify-center">
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
      ></path>
    </svg>{" "}
  </div>
);

export default Button;
