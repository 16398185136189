import { twMerge } from "tailwind-merge";

const Card = ({ className, children }) => {
  return (
    <div
      className={twMerge("bg-gray-50 py-6 px-8 shadow rounded-md", className)}
    >
      {children}
    </div>
  );
};

export default Card;
