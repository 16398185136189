import { passedDays } from "../../utils/dueTimes";
import { truncate } from "../../utils/truncate";
import { Button } from "../Buttons";
import dailyDO from "../../assets/dailydo.ai.png";

const DueActivities = ({
  activityTitle,
  dueDate,
  activityInstruction,
  programTitle,
  action,
  buttonText = "Submit",
  image = "",
  totalSubmissions = 0,
}) => {
  const d = passedDays(dueDate);

  return (
    <div>
      <div className="cursor-pointer" onClick={action}>
        <div className="relative shadow">
          {d > 1 ? (
            <span className="absolute top-3 right-3 bg-blue-300 px-4 py-1 rounded-full  text-sm">
              <span className="text-black text-sm">
                {d - 1} day{d - 1 > 1 && "s"} overdue
              </span>
            </span>
          ) : (
            <span className="absolute top-3 right-3 bg-blue-300 px-4 py-1 rounded-full  text-sm">
              <span className="text-black text-sm">
                {totalSubmissions}{" "}
                {totalSubmissions > 1 ? "submissions" : "submission"}
              </span>
            </span>
          )}
          <div className="h-[287px] rounded-sm overflow-hidden">
            <img
              src={image ? image : dailyDO}
              className="rounded-md h-full w-full object-cover"
            />
          </div>
        </div>
        <div>
          <h4 className="uppercase text-xs  mt-3 text-gray-500">
            {truncate(programTitle, 20)}
          </h4>
          <h3 className="font-medium text-lg">{truncate(activityTitle, 20)}</h3>
          <p className="text-gray-400 mt-1">
            {truncate(activityInstruction, 100)}
          </p>
          <div className="mt-5 grid grid-cols-2">
            <div></div>
            <Button variant="outline" size="sm" onClick={action}>
              {buttonText} &rarr;{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DueActivities;
