import React, { useId } from "react";

const Modal = ({ title, children, actions, isOpen, onClose }) => {
  const id = useId();

  const handleEscape = (e) => {
    if (e.key === "Escape" && isOpen) {
      onClose();
    }
  }

  const handleOutsideClick = (e) => {
    if (e.target.id === id) {
      onClose();
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleOutsideClick);
    }
  }, []);

  return (
    <>
      <div
        id={id}
        tabIndex={-1}
        aria-hidden={!isOpen}
        className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full items-start justify-center bg-black/50 ${
          isOpen ? "flex" : "hidden"
        }`}
      >
        <div className="relative w-full max-w-2xl max-h-full mt-32 mb-10">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-start justify-between p-4 border-b rounded-t">
              <h3 className="text-xl font-semibold text-gray-500">{title}</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                data-modal-hide="defaultModal"
                aria-controls={id}
                aria-haspopup="true"
                aria-expanded={isOpen}
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-6 bg-gray-50">{children}</div>
            {actions && (
              <div className="flex items-center px-5 py-3 space-x-2 border-t border-gray-200 justify-end">
                {actions}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
